import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { environment } from '@enviroment';

// Services
import { HttpService } from '@shared/services/http.service';
import { LoadingService } from '@shared/services/loading.service';

// Interfaces
import { ILanguage } from '@shared/interface/language.interface';
import { IResponseLanguages } from '@shared/interface/response.interface';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private path: any;

  public languages: Array<ILanguage>;

  constructor(
    private httpService: HttpService,
    private loadingService: LoadingService
  ) {
    this.languages = [];
    this.path = environment.api.language;
  }

  public getAllLanguages(): void {
    if (this.languages.length === 0) {
      this.loadingService.show();
      this.httpService.get<IResponseLanguages>(this.path.list).subscribe(r => {
        if (r) {
          this.languages = r.idiomas;
        }

        this.loadingService.hide();
      });
    }
  }
}

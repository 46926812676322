import { OnInit, Component, ViewChild, ElementRef, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { FileService } from '@shared/services/file.service';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpResponse
} from '@angular/common/http';
import { DictonaryService } from '@shared/services/dictonary.service'

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  @Input() urlImage: string;
  @Output() dataFiles = new EventEmitter();
  public selectedFiles: FileList;
  public progressInfos = [];
  public message = '';

  public fileInfos: Observable<any>;
  public progress: { porcentaje: number } = { porcentaje: 0 };
  @ViewChild('fileUpload', { static: false }) public fileUpload: ElementRef;
  public files = [];
  public showProgress: boolean;
  public imagePath: any;
  public imgURL: any = "https://www.gtscentral.com/recursos/centershop/img/sin_imagen.png";

  constructor(
    private fileService: FileService,
    public dictionaryService: DictonaryService
    ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    let imageValue = changes.urlImage.currentValue;
    if(imageValue){
      this.imgURL = imageValue;
    }
  }

  public selectFiles(event) {
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
  }

  public updaload(file) {
    const formData = new FormData();
    formData.append('file', file.data);
    file.inProgress = true;

    this.fileService.upload(formData).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type == HttpEventType.UploadProgress) {
          this.showProgress = true;
          file.progress = Math.round((event.loaded * 100) / event.total);
        } else if (event.type == HttpEventType.Response) {

          setTimeout(() => {
            this.showProgress = false;
          }, 1500);
        }
      },
      (err: HttpErrorResponse) => {
        file.inProgress = false;
      }
    );
  }

  public uploadFileTwo(id, archivo) {
    this.progressInfos[id] = { value: 0, fileName: archivo.name };

    this.fileService.test(archivo).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos[id].value = Math.round(
            (100 * event.loaded) / event.total
          );
        } else if (event instanceof HttpResponse) {
          // this.fileInfos = this.uploadService.getFiles();
          this.files = event.body;
        }
      },
      err => {
        this.progressInfos[id].value = 0;
        this.message = 'Could not upload the file:' + archivo.name;
      }
    );
  }

  public uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    // * Se comenta la linea por el momento no es requerido
    // this.files.forEach(file => { this.updaload(file); });
  }

  public onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }

      this.uploadFiles();
    };

    fileUpload.click();
  }

  public onChange(file: File) {
    if (file) {
      this.dataFiles.emit(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = event => {
        this.imgURL = reader.result;
      };
    }
  }
}

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@enviroment';

// Services
import { HttpService } from '@shared/services/http.service';
import { AlertService } from '@shared/services/alert.service';
import { LoadingService } from '@shared/services/loading.service';
// Interfaces
import {
  IOutfit,
  IOutfitFormData,
  IOutfitData
} from '@shared/interface/outfit.interface';

@Injectable({
  providedIn: 'root'
})
export class OutfitsService {
  private path: any;
  public outfit: IOutfitData;

  constructor(
    private httpService: HttpService,
    private loadingService: LoadingService,
    private alertService: AlertService
  ) {
    this.path = environment.api.outfit;
  }

  public postOutfit(data: IOutfitFormData): Observable<IOutfitData> {
    return this.httpService.post<IOutfitData>(this.path.getOutfit, data);
  }

  public getOutfit(data: IOutfitFormData): void {
    this.loadingService.show();
    this.postOutfit(data).subscribe(
      (data: IOutfitData) => {
        console.log('outfit ==>', data);
        this.loadingService.hide();
        this.outfit = data;
        return data;
      },
      err => {
        console.error(err);
        this.loadingService.hide();
        return this.alertService.error(err);
      }
    );
  }
}

import { api } from './enviroment.api';
import { images } from './enviroment.images';
import { language } from './enviroment.language';
import { errorMessages } from './enviroment.error-messages';

export const environment = {
  language,
  idLangugage: 1,
  limitPerPage: 10,
  limitPoints: 10,
  limitPrintSize: 3,
  limitPrintSaturation: 5,
  production: false,
  errorMessages: { ...errorMessages },
  images,
  api: {
    ...api,
    //base: 'http://localhost:8000/api/auth'
    //base: 'http://phpstack-49485-1573590.cloudwaysapps.com/api/auth'
    //base: 'https://outfit365.789.com.mx/api/auth'
    base: 'https://api.outfit365.app/api/auth'
  }
};

export enum TypeData {
  INT,
  DATE,
  MONEY,
  IMAGEN,
  STRING,
  STATUS,
  PLAN
}

export interface ITable {
  actions?: ITableActions;
  header: Array<ITableHeader>;
}

export interface ITableHeader {
  key: string;
  text: string;
  type?: TypeData;
}

export interface ITableActions {
  edit?: IAction;
  view?: IAction;
  delete?: IAction;
  create?: IAction;
  search?: boolean;
}

export interface IAction {
  url?: string;
  fieldKey?: any;
  text?: string;
}

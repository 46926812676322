import { Subscription } from 'rxjs';
import {
  Component,
  Input,
  Output,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from '@enviroment';

// Services
import { AlertService } from '@shared/services/alert.service';
import { LoadingService } from '@shared/services/loading.service';

// Interfaces
import { ITable, TypeData } from '@shared/interface/table.interface';
import { IResponseTable } from '@shared/interface/response.interface';

// Components
import { PaginadorComponent } from '@shared/components/paginador/paginador.component';
import { DictonaryService } from '@shared/services/dictonary.service';
import { ITranslation } from '@shared/interface/translation.interface';

@Component({
  selector: 'app-table-detail',
  styleUrls: ['./table-detail.component.scss'],
  templateUrl: './table-detail.component.html'
})
export class TableDetailComponent implements OnInit, OnDestroy {
  private currentPage: number;
  private itemsPerPage: number;

  private subscriptionList: Subscription;
  public translation: ITranslation;

  public query: string;
  public itemsPerPageInital: number;
  public data: IResponseTable<Array<any>>;
  public customInitialPaginatorPage: number | null = null;

  @Input('config') public table: ITable;
  @Input('service') public service: any;
  @Input('getList') public getList = true;
  @Input('queryGetAllList') public queryGetAllList: any | null = null;
  @Input('path') public path: string | null = null;

  @ViewChild(PaginadorComponent, { static: false })
  private paginator: PaginadorComponent;

  constructor(
    private alertService: AlertService,
    private loadingServive: LoadingService,
    public dictionaryService: DictonaryService,
    private router: Router,
    public route: ActivatedRoute
  ) {
    this.query = null;
    this.data = {
      total: 0,
      lista: [],
      paginaActual: 0,
      ultimaPagina: 0,
      elementosPagina: 0
    };
    this.translation = environment.language;
    this.currentPage = 1;
    this.itemsPerPage = environment.limitPerPage;
    this.itemsPerPageInital = environment.limitPerPage;

    this.route.queryParamMap.subscribe((params: ParamMap) => {
      const initialPage = params.get('actualPage');
      if (initialPage) {
        this.customInitialPaginatorPage = parseInt(initialPage);
        this.currentPage = this.customInitialPaginatorPage;
      }
    });
  }

  public getAllList(
    query?: any,
    finalAction: () => void = () => {},
    customPage?: number
  ): void {
    const queryParams = query || this.queryGetAllList;
    this.loadingServive.show();

    this.subscriptionList = this.service
      .getList(customPage || this.currentPage, this.itemsPerPage, queryParams)
      .subscribe(response => {
        this.data = response || {};
        if (finalAction) {
          finalAction();
        }
        this.loadingServive.hide();
      });
  }

  public get typeData(): typeof TypeData {
    return TypeData;
  }

  public search(query: string): void {
    this.query = query;
    this.currentPage = 1;
    this.getAllList(query.trim().length == 0 ? null : query);
  }

  public delete(id: string): void {
    this.alertService
      .confirmDialog('¿Está seguro que desea eliminarlo?')
      .then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.loadingServive.show();
          this.service.delete(id).subscribe(response => {
            this.loadingServive.hide();
            if (response) {
              this.currentPage =
                this.data.lista.length == 1 && this.currentPage > 1
                  ? this.currentPage - 1
                  : this.currentPage;
              this.getAllList();

              this.alertService.success(response).then(res => {
                if (
                  res.isConfirmed &&
                  this.data.total > environment.limitPerPage
                ) {
                  this.paginator.lastPage = this.data.ultimaPagina;
                  this.paginator.tamanioRespuesta = this.data.total;
                  this.paginator.obtenerInformacionPaginador(this.currentPage);
                }
              });
            }
          });
        }
      });
  }

  public getElementsByPage(event: number) {
    this.currentPage = 1;
    this.itemsPerPage = Number(event);
    this.getAllList();
  }

  public getPageCurrent(page: number) {
    this.currentPage = page;
    if (this.getList) {
      this.getAllList();
    }
  }

  public getCountCells(): number {
    if (this.table) {
      const total = this.table.header.length;
      if (this.table.actions) {
        return total + 1;
      }
      return total;
    }
    return 1;
  }

  ngOnInit(): void {
    if (this.getList) {
      this.getAllList();
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptionList) {
      this.subscriptionList.unsubscribe();
    }
  }

  public getActualPaginatorPage = (actualPage: number): void => {
    if (this.path) {
      this.router.navigate([this.path], {
        queryParams: { actualPage },
        queryParamsHandling: 'merge'
      });
    }
  };
}

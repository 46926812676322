import { Injectable } from '@angular/core';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor() {}

  private static config(options: any): Promise<any> {
    let {
      icon,
      title,
      text,
      html,
      showCloseButton,
      showCancelButton,
      confirmButtonText
    } = options;

    return Swal.fire({
      icon,
      html: html || '',
      text: text || '',
      title: title || '',
      buttonsStyling: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      cancelButtonText: 'Cancelar',
      customClass: {
        cancelButton: `btn btn-danger`,
        confirmButton: `btn btn-success ${showCancelButton ? 'mr-3' : ''}`
      },
      showCancelButton,
      showCloseButton: showCloseButton || false,
      confirmButtonText: confirmButtonText || 'Aceptar'
    });
  }

  public error(text: string): void {
    AlertService.config({
      text,
      icon: 'error',
      title: 'Ocurrio un error',
      showCancelButton: false
    }).then();
  }

  public success(text: string): Promise<any> {
    return AlertService.config({
      text,
      icon: 'success',
      showCancelButton: false
    });
  }

  public showHideLoadDialog(type: string): void {
    if (type == 'load') {
      Swal.fire({
        title: 'Por favor espere...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
      }).then();
    } else if (type == 'close') {
      Swal.close();
    }
  }

  public confirmDialog(text: string): Promise<any> {
    return Swal.fire({
      text,
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Continuar',
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#dc3545'
    });
  }
}

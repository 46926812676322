import { OnInit, Component, Input } from '@angular/core'

// services
import { DictonaryService } from '@shared/services/dictonary.service'

@Component({
  selector: 'app-button-save',
  templateUrl: './button-save.component.html',
  styleUrls: ['./button-save.component.scss']
})
export class ButtonSaveComponent implements OnInit {

  @Input() action: () => void;

  constructor(
    public dictionaryService: DictonaryService
  ) {}

  ngOnInit() {}

  click = () => {
    if(this.action) this.action();
    else return;
  }
}

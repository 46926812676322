import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// services
import { BigDesktopService } from '@shared/services/util/bigDesktop.service';
import { DictonaryService } from '@shared/services/dictonary.service';
import { OutfitsService } from '@shared/services/outfits.service';
import { AlertService } from '@shared/services/alert.service';
import { GarmentService } from '@shared/services/garment.service';

// Interfaces
import { ITranslation } from '@shared/interface/translation.interface';
import { environment } from '@enviroment';
import { IResponseGarment } from '@shared/interface/response.interface';

interface IForm {
  event_type: number;
  gender: number | '';
}

@Component({
  selector: 'app-outfits',
  templateUrl: './outfits.component.html',
  styleUrls: ['./outfits.component.scss']
})
export class OutfitsComponent implements OnInit {
  public form: FormGroup;
  public submitted: boolean = false;
  public catalogs: IResponseGarment;
  public translation: ITranslation;
  public itemsToUpload: boolean;
  public outfit: boolean = false;
  public genderSelected: any = {
    id: null
  };
  public loadedStyles: string[] = [];

  constructor(
    public bigDesktopService: BigDesktopService,
    public dictionaryService: DictonaryService,
    public service: OutfitsService,
    private formBuilder: FormBuilder,
    public alert: AlertService,
    public garmentService: GarmentService
  ) {
    this.translation = environment.language;
    this.form = this.formBuilder.group({
      event_type: ['', Validators.required],
      gender: ['', Validators.required],
      style: ['', Validators.required],
      age: ['', Validators.required],
      temperature: ['', Validators.required],
      humidity: ['', Validators.required],
      colorimetria: ['', Validators.required],
      body_shape: ['', Validators.required]
    });
    this.filterGender();
  }

  ngOnInit(): void {
    this.bigDesktopService.isBigDesktop();
    this.garmentService.getCatalogs().subscribe(
      data => (
        (this.catalogs = data),
        console.log(this.catalogs),
        this.form.patchValue({ gender: 1 })
      ),
      err => console.error(err)
    );
  }

  filterGender(): void {
    this.form.valueChanges.subscribe((form: IForm) => {
      const { gender } = form;
      // Se filtra genero para el form
      if (gender !== '' && gender !== this.genderSelected.id) {
        this.genderSelected = this.catalogs.generos.find(genero => {
          return genero.id === gender;
        });
        // Siempre que se cambie de genero, se resetea el state del form
        this.form.patchValue({
          style: '',
          age: '',
          temperature: '',
          humidity: '',
          colorimetria: '',
          body_shape: ''
        });
      }
    });
  }

  async onSubmit(): Promise<void> {
    if (this.form.status === 'VALID') {
      this.submitted = true;
      this.service.getOutfit(this.form.value);
    }
  }

  getFormControlName(catalogeType: string): string {
    switch (catalogeType) {
      case 'EDAD':
        return 'age';
      case 'ESTILO':
        return 'style';
      case 'TEMPERATURA':
        return 'temperature';
      case 'HUMEDAD':
        return 'humidity';
      case 'ESTATURA':
        return 'height';
      case 'COLORIMETRIA':
        return 'colorimetria';
      case 'TIPO_CUERPO':
        return 'body_shape';
      default:
        return '';
    }
  }

  getStylesByOrden = (orden: number): string => `img-orden-${orden}`;

  /*
  getSizeStyles(type: 'accesorio' | 'prenda'): string {
    const stylesAccesorios = [
      'img-postion-accesory-one',
      'img-postion-accesory-two',
      'img-postion-accesory-three'
    ];

    const stylesPrendas = [
      'img-postion-prendas-one',
      'img-postion-prendas-two',
      'img-postion-prendas-three'
    ];

    function getRandomImage(imgAr: string[]): string {
      let num = Math.floor(Math.random() * imgAr.length);
      let style = imgAr[num];
      return style
    }

    return getRandomImage(
      type === 'accesorio' ? stylesAccesorios : stylesPrendas
    );
  }*/
}

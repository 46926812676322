export const api = {
  user: {
    auth: '/login',
    me: '/usuario',
    logout: '/logout',
    changeLanguage: '/persona/idioma',
    changePassword: '/contrasenia/cambiar',
    allUsers: '/usuarios',
    user: '/usuarios',
    deleteUser: '/usuarios',
    updateUser: '/usuarios',
    saveUser: '/usuario',
    roles: '/roles'
  },
  client: {
    clients: '/clients',
    details: '/client/profile',
    pushNotification: '/clients/notifications'
  },
  language: {
    list: '/idiomas?estatus=ACTIVO',
    dictionary: '/diccionario/${id}'
  },
  company: {
    company: '/compania',
    allCompaniesByPage: '/companias',
    allCompanies: '/companias/todas'
  },
  style: {
    allStyles: '/catalogo/estilos'
  },
  colorimetry: {
    colorimetries: '/catalogo/colorimetrias'
  },
  garment: {
    garment: '/prendas',
    getGarment: '/prendas',
    putGarment: '/prendas',
    deleteGarment: '/prendas',
    catalogs: '/prendas/catalogo',
    allGarments: '/prendas',
    allCompanies: '/companias/todas'
  },
  brand: {
    brand: '/marca',
    allBrandsByPage: '/marcas',
    levels: '/marcas/niveles'
  },
  banners: {
    banner: '/anuncio',
    allBanners: '/anuncios'
  },
  brandLevel: {
    levels: '/catalogo/nivel/marca'
  },
  userTerms: {
    terms: '/terminos'
  },
  outfit: {
    getOutfit: '/obtener-outfit'
  }
};

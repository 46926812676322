import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Modules
import { SharedComponentModule } from '@shared/components/shared.component.module';
import { CKEditorModule } from 'ckeditor4-angular';

// Components
import { UserTermsComponent } from '@components/admin/user-terms/user-terms.component';

@NgModule({
  declarations: [
    UserTermsComponent 
  ],
  imports: [
      CommonModule, 
      ReactiveFormsModule, 
      FormsModule, 
      SharedComponentModule,
      CKEditorModule
    ]
})

export class UserTermsModule { }

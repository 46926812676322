import { AbstractControl, FormGroup } from '@angular/forms';
import {
  Directive,
  DoCheck,
  ElementRef,
  Input,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[appInputError]'
})
export class InputErrorDirective implements DoCheck {
  @Input('field') public field: AbstractControl;
  @Input('submitted') public submitted: boolean;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngDoCheck() {
    this.renderer.removeClass(this.elementRef.nativeElement, 'border-danger');

    if (this.field.errors && (this.field.touched || this.submitted)) {
      this.renderer.addClass(this.elementRef.nativeElement, 'border-danger');
    }
  }
}

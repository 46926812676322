<nav id="menu" class="navbar navbar-expand-sm navbar-dark bg-dark">
  <ul class="navbar-nav ml-auto">
    <li class="nav-item">
      <a
        class="nav-link"
        [routerLink]="['/admin/perfil']"
        routerLinkActive="active"
      >
        <i class="fas fa-user"></i>
        {{ dictonaryService.getTranslation(translationKey.profile) }}
        <span class="sr-only">(current)</span>
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        href="javascript:void(0)"
        (click)="userService.logout()"
      >
        <i class="fas fa-sign-out-alt"></i>
        {{ dictonaryService.getTranslation(translationKey.logout) }}
      </a>
    </li>
  </ul>
</nav>

<app-layout>
  <div class="container-fluid">
    <div class="row">
      <div class="col-4">
        <div class="accordion" id="accordionParent">
          <ng-container *ngFor="let item of responseUserTems; let i = index">
            <div class="card">
              <div class="card-header" id="{{ i }}">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left"
                    [ngClass]="{ collapsed: i > 0 }"
                    type="button"
                    data-toggle="collapse"
                    [attr.data-target]="'#collapse_' + i"
                    [attr.aria-expanded]="i == 0"
                  >
                    {{ item.nombre }}
                  </button>
                </h2>
              </div>

              <div
                [attr.id]="'collapse_' + i"
                class="collapse"
                [ngClass]="{ show: i == 0 }"
                data-parent="#accordionParent"
              >
                <div class="card-body">
                  <div
                    class="nav flex-column nav-pills nav-user-terms"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <ng-container
                      *ngFor="let terminos of item.terminos; let j = index"
                    >
                      <a
                        class="nav-link"
                        [ngClass]="{ active: j == 0 }"
                        data-toggle="pill"
                        (click)="getData(terminos.texto, terminos.id)"
                        role="tab"
                        aria-selected="true"
                        >{{
                          dictionaryServ.getTranslation(
                            terminos.claveTraduccion
                          )
                        }}</a
                      >
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-8">
        <ckeditor [(ngModel)]="model"></ckeditor>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12 text-center">
      <button type="button" class="btn btn-dark" (click)="updateUserTerms()">
        <i class="fas fa-check"></i>&nbsp;&nbsp;Guardar
      </button>
    </div>
  </div>
</app-layout>

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'

// services
import { BrandLevelService } from '@shared/services/brand-level.service'
import { BigDesktopService } from '@shared/services/util/bigDesktop.service'
import { DictonaryService } from '@shared/services/dictonary.service'

// Interfaces
import { IParamsColorimetry } from '@shared/interface/colorimetry.interface'
import { ITranslation } from '@shared/interface/translation.interface';
import { environment } from '@enviroment';

@Component({
  selector: 'app-brand-level',
  templateUrl: './brand-level.component.html',
  styleUrls: ['./brand-level.component.scss']
})

export class BrandLevelComponent implements OnInit {
  public list:any[]
  public listItems: string[]
  public allParams: IParamsColorimetry
  public itemsToUpload: boolean
  public imgURL: any = "https://www.gtscentral.com/recursos/centershop/img/sin_imagen.png"

  private category: string
  public edit: boolean
  public translation: ITranslation;

  constructor(
    private route: ActivatedRoute,
    public bigDesktopService: BigDesktopService,
    public service: BrandLevelService,
    public dictionaryService: DictonaryService
  ) {
    this.listItems = ['Shoes', 'Blouse', 'Pants', 'Complement', 'Accesory', 'Wedding dress']
    this.list = [{n: 'TOP'}, {n: 'HIGHT'}, {n: 'MEDIUM'},{n: 'LIGHT'},]
    this.edit = false
    this.translation = environment.language;
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.category = params.get('category')
      this.service.getCategories(this.category)
    })
    this.bigDesktopService.isBigDesktop()
    this.service.getLevels(this.category)
  }

  changeEdit(isEdit: boolean) {
    if(!isEdit) this.service.listOfLevelsToUpload = []
    this.edit = isEdit
  }

  addLevelToUpdate(img: File, id: number) {
    if(img && id) this.service.newUpdateBrand(img, id)
  }

  update = () => this.service.updateBrandLevel(this.category)
}
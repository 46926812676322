<app-layout>
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            class="nav-link active"
            href="#information"
            data-toggle="tab"
            role="tab"
          >
            <i class="mr-2 fas fa-info"></i>
            <span>
              {{ dictonaryService.getTranslation(translation.informationText) }}
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#password" data-toggle="tab" role="tab">
            <i class="mr-2 fas fa-key"></i>
            <span>
              {{ dictonaryService.getTranslation(translation.passwordText) }}
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#language" data-toggle="tab" role="tab">
            <i class="mr-2 fas fa-globe-americas"></i>
            <span>
              {{ dictonaryService.getTranslation(translation.languageText) }}
            </span>
          </a>
        </li>
      </ul>
    </div>

    <div class="card-body">
      <div class="tab-content">
        <!-- user information -->
        <div class="tab-pane active" id="information" role="tabpanel">
          <div class="row justify-content-center">
            <div class="col-4 text-center">
              <i class="fas fa-user fa-3x"></i>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-8 col-10">
              <div class="form-group">
                <label for="name">
                  {{ dictonaryService.getTranslation(translation.nameText) }}
                </label>
                <input
                  id="name"
                  type="text"
                  class="form-control"
                  [value]="user.getNameUser()"
                  readonly
                />
              </div>
            </div>
            <div class="w-100"></div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-8 col-10">
              <div class="form-group">
                <label for="email">
                  {{ dictonaryService.getTranslation(translation.emailText) }}
                </label>
                <input
                  id="email"
                  type="text"
                  class="form-control"
                  [value]="user.email"
                  readonly
                />
              </div>
            </div>
            <div class="w-100"></div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-8 col-10">
              <div class="form-group">
                <label for="rol">Rol</label>
                <input
                  id="rol"
                  type="text"
                  class="form-control"
                  [value]="user.rol"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
        <!-- ./user information -->

        <!-- change password -->
        <div class="tab-pane" id="password" role="tabpanel">
          <form [formGroup]="formPassword" (ngSubmit)="changePassword()">
            <div class="row justify-content-center">
              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-8 col-10 mt-3">
                <div class="form-group">
                  <label for="old-password">
                    {{
                      dictonaryService.getTranslation(
                        translation.passwordOldText
                      )
                    }}
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id="old-password"
                    formControlName="oldPassword"
                    placeholder="Ingres su contraseña anterior"
                    required
                    appInputError
                    [field]="formPassword.get('oldPassword')"
                  />
                  <app-form-error
                    [control]="formPassword.get('oldPassword')"
                  ></app-form-error>
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-8 col-10 mt-3">
                <div class="form-group">
                  <label for="new-password"> Nueva contraseña </label>
                  <a
                    href="javascript:void(0)"
                    class="d-inline-block pl-1"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Mínimo 8 caracteres, una letra mayúscula, un signo especial y un número"
                  >
                    <i class="far fa-question-circle"></i>
                  </a>
                  <input
                    type="password"
                    class="form-control"
                    id="new-password"
                    formControlName="newPassword"
                    placeholder="Ingres su contraseña nueva contraseña"
                    required
                    appInputError
                    [field]="formPassword.get('newPassword')"
                  />
                  <app-form-error
                    [control]="formPassword.get('newPassword')"
                  ></app-form-error>
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-8 col-10 mt-3">
                <div class="form-group">
                  <label for="repeat-new-password">Repetir contraseña</label>
                  <input
                    type="password"
                    class="form-control"
                    formControlName="repeatNewPassword"
                    id="repeat-new-password"
                    placeholder="Ingres su contraseña nuevamente"
                    required
                    appInputError
                    [field]="formPassword.get('repeatNewPassword')"
                  />
                  <app-form-error
                    [control]="formPassword.get('repeatNewPassword')"
                  ></app-form-error>
                </div>
              </div>
            </div>

            <div class="text-center mt-4">
              <button
                class="btn btn-dark btn-sm"
                [disabled]="formPassword.invalid"
              >
                Cambiar contraseña
              </button>
            </div>
          </form>
        </div>
        <!-- ./change password -->

        <!-- change language -->
        <div class="tab-pane fade show" id="language" role="tabpanel">
          <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-8 col-10">
              <select
                name="languaje"
                id="languaje"
                class="form-control"
                #languajeSelect
              >
                <option
                  *ngFor="let lang of languageService.languages"
                  [selected]="idDictionary === lang.id"
                  [value]="lang.id"
                >
                  {{ lang.nombre }}
                </option>
              </select>

              <div class="text-center mt-4">
                <button
                  class="btn btn-dark btn-sm"
                  (click)="changeLang(languajeSelect.value)"
                >
                  Cambiar idioma
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- ./change language -->
      </div>
    </div>
  </div>
</app-layout>

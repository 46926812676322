import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search',
  styleUrls: ['./search.component.scss'],
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {
  @Output() public query: EventEmitter<string>;

  constructor() {
    this.query = new EventEmitter();
  }

  ngOnInit() {}

  public search(query: string) {
    this.query.emit(query);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Modules
import { AdminRoutingModule } from '@components/admin/admin-routing.module';
import { SharedComponentModule } from '@shared/components/shared.component.module';
import { SharedDirectiveModule } from '@shared/directives/shared.directive.module';
import { UserTermsModule } from '@components/admin/user-terms/user-terms.module';

// Components
import { ProfileComponent } from '@components/admin/profile/profile.component'
import { DashboardComponent } from '@components/admin/dashboard/dashboard.component'
import { ColorimetryComponent } from '@components/admin/colorimetry/colorimetry.component'
import { StylesComponent } from '@components/admin/styles/styles.component'
import { BrandLevelComponent } from './brand-level/brand-level.component';
import { OutfitsComponent } from '@components/admin/outfits/outfits.component'
import { NotificationsComponent } from '@components/admin/Notifications/Notifications.component';
import { OrderComponent } from './dashboard/order/order.component'

@NgModule({
  declarations: [
    ProfileComponent,
    DashboardComponent,
    ColorimetryComponent,
    StylesComponent,
    BrandLevelComponent,
    OutfitsComponent,
    NotificationsComponent,
    OrderComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedComponentModule,
    UserTermsModule,
    ReactiveFormsModule,
    FormsModule,
    SharedDirectiveModule
  ]
})
export class AdminModule {}

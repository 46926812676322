import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private isLoad: BehaviorSubject<boolean>;

  public isLoad$: Observable<boolean>;

  constructor() {
    this.isLoad = new BehaviorSubject<boolean>(false);
    this.isLoad$ = this.isLoad.asObservable();
  }

  public show(): void {
    this.isLoad.next(true);
  }

  public hide(): void {
    this.isLoad.next(false);
  }
}
import { take } from 'rxjs/operators';
import { Component, DoCheck, OnInit } from '@angular/core';

// Service
import { LoadingService } from '@shared/services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements DoCheck {
  public showLoading: boolean;

  constructor(private loadingService: LoadingService) {
    this.showLoading = false;
  }

  ngDoCheck(): void {
    this.loadingService.isLoad$
      .pipe(take(1))
      .subscribe(isLoading => (this.showLoading = isLoading));
  }
}

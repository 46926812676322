<app-layout>
  <div class="card">
    <h5 class="card-header bg-dark text-white text-left">
      {{ dictionaryService.getTranslation(translation.outfitRules) }}
    </h5>

    <!-- Formulario Reglas de outfit -->
    <div class="body container" *ngIf="catalogs">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row mt-1 p-3 justify-content-center">
          <div class="col-12 col-md-4 mb-3">
            <h6 class="text-left mx-1">
              {{ dictionaryService.getTranslation(translation.genderText) }}
            </h6>
            <div class="form-group">
              <select
                id="genderSelect"
                class="form-control"
                formControlName="gender"
              >
                <option
                  [ngValue]="gender.id"
                  *ngFor="let gender of catalogs.generos"
                >
                  {{ dictionaryService.getTranslation(gender.claveTraduccion) }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-4 mb-3">
            <h6 class="text-left mx-1">
              {{ dictionaryService.getTranslation(translation.eventType) }}
            </h6>
            <div class="form-group">
              <select
                id="event"
                class="form-control"
                formControlName="event_type"
              >
                <option
                  [ngValue]="eventType.id"
                  *ngFor="let eventType of catalogs.tipoEventos"
                >
                  {{
                    dictionaryService.getTranslation(eventType.claveTraduccion)
                  }}
                </option>
              </select>
            </div>
          </div>
          <ng-container *ngIf="genderSelected.id; else noGenderSelected">
            <ng-container *ngFor="let catalogo of genderSelected.tipoCatalogo">
              <div
                class="col-12 col-md-4 mb-3"
                *ngIf="catalogo.tipo !== 'ESTATURA'"
              >
                <h6 class="text-left mx-1">
                  {{
                    dictionaryService.getTranslation(catalogo.claveTraduccion)
                  }}
                </h6>
                <div class="form-group">
                  <select
                    class="form-control"
                    [formControlName]="getFormControlName(catalogo.tipo)"
                    appSelectError
                    [submitted]="submitted"
                  >
                    <option
                      [ngValue]="item.id"
                      *ngFor="let item of catalogo.catalogo"
                    >
                      {{
                        dictionaryService.getTranslation(item.claveTraduccion)
                      }}
                    </option>
                  </select>
                  <app-form-error
                    [submitted]="submitted"
                    [control]="form.get(getFormControlName(catalogo.tipo))"
                  ></app-form-error>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #noGenderSelected>
            <div class="col-12"></div>
          </ng-template>
          <div class="col-12 mt-2 text-center">
            <app-button-save></app-button-save>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="card m-2">
    <h5 class="card-header bg-dark text-white text-left">Outfit</h5>
    <div class="card-body">
      <div class="row p-3">
        <div class="col-12">
          <div>
            <div class="container-outfit container text-center d-none d-lg-block outfit">
              <div
                *ngIf="service.outfit; else noOutfit"
                [ngClass]="service.outfit ? 'outfit-size' : ''"
              >
                <!-- Prendas -->
                <div *ngIf="service.outfit.PRENDAS" id="outfit-prendas">
                  <img
                    *ngFor="let prenda of service.outfit.PRENDAS; let i = index"
                    src="{{ prenda.imagen }}"
                    alt="{{ prenda.marca.nombre }}"
                    [ngClass]="getStylesByOrden(prenda.orden)"
                  />
                </div>

                <!-- Accesorios -->
                <div *ngIf="service.outfit.ACCESORIOS" id="outfit-accesorios">
                  <img
                    *ngFor="
                      let accesorio of service.outfit.ACCESORIOS;
                      let i = index
                    "
                    src="{{ accesorio.imagen }}"
                    alt="{{ accesorio.marca.nombre }}"
                    class="img-orden-13"
                  />
                </div>
              </div>
              <ng-template #noOutfit>
                <div>
                  <h2>Completa el formulario para generar un outfit</h2>
                </div>
              </ng-template>
            </div>

            <div class="d-block d-lg-none">
              <h4 class="text-center">Outfit no disponible para mobile</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-layout>

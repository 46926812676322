import { Component, OnInit } from '@angular/core';

// services
import { BigDesktopService } from '@shared/services/util/bigDesktop.service';
import { DictonaryService } from '@shared/services/dictonary.service';

// Interfaces
import {
  ICatalog,
  ICatalogType,
  IGender
} from '@shared/interface/gender.interface';
import { ISubCategory } from '@shared/interface/category.interface';
import { GarmentService } from '@shared/services/garment.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@shared/services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  public formNotification: FormGroup;
  public submitted = false;

  public catalogAge: Array<ICatalog>;
  public catalogStyle: Array<ICatalog>;
  public catalogHeight: Array<ICatalog>;
  public catalogHumidity: Array<ICatalog>;
  public catalogBodyType: Array<ICatalog>;
  public subGarments: Array<ISubCategory>;
  public catalogTemperature: Array<ICatalog>;
  public catalogColorimetry: Array<ICatalog>;

  constructor(
    // private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public notificationService: NotificationService,
    public garmentService: GarmentService,
    public bigDesktopService: BigDesktopService,
    public dictionaryService: DictonaryService
  ) {
    this.garmentService.getFillCatalogs();

    this.formNotification = this.formBuilder.group({
      title: ['', Validators.required],
      body: ['', Validators.required],
      gender: ['', Validators.required],
      style: ['', Validators.required],
      bodyShape: ['', Validators.required],
      colorimetry: ['', Validators.required]
    });
  }

  public fillCatalogType(id: string): void {
    this.catalogAge = [];

    const gender = this.garmentService.catalogs.generos.find(
      (g: IGender) => g.id === Number(id)
    );

    if (gender && gender.tipoCatalogo) {
      gender.tipoCatalogo.forEach((catalogType: ICatalogType) => {
        switch (catalogType.tipo) {
          case 'ESTILO':
            this.catalogStyle = catalogType.catalogo;
            break;
          case 'COLORIMETRIA':
            this.catalogColorimetry = catalogType.catalogo;
            break;
          case 'EDAD':
            this.catalogAge = catalogType.catalogo;
            break;
          case 'TEMPERATURA':
            this.catalogTemperature = catalogType.catalogo;
            break;
          case 'HUMEDAD':
            this.catalogHumidity = catalogType.catalogo;
            break;
          case 'ESTATURA':
            this.catalogHeight = catalogType.catalogo;
            break;
          case 'TIPO_CUERPO':
            this.catalogBodyType = catalogType.catalogo;
            break;
        }
      });
    }
  }

  public sendNotification(): void {
    this.submitted = true;
    if (this.formNotification.valid) {
      this.notificationService.sentPushNotifications(
        this.formNotification.value
      );
    }
  }

  ngOnInit(): void {
    this.bigDesktopService.isBigDesktop();
  }
}

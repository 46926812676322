<app-layout>
  <div class="container-fluid">
    <div class="text-center">
      <h2 class="mt-2">
        {{  dictionaryService.getTranslation(translation.stylesTitle)  }}
      </h2>
      <div class="row mt-1 justify-content-center">
        <div class="col-md-2 col-lg-3 d-none d-lg-inline-block">
          <div class="justify-content-start text-left mt-2">
            <ng-container *ngIf="service.data">
              <ng-container *ngFor="let genderItem of service.data; index as i" >
                <button class="btn button-to-select d-none d-lg-inline-block"
                  [routerLink]="['/admin/estilos/']"
                  [queryParams]="{ gender: genderItem.nombre }"
                  [ngClass]="service.gender === genderItem.nombre ? 'button-selected' : 'button-unselected'"
                >
                  <i
                    [ngClass]="genderIcon(genderItem.nombre)"
                    class="d-inline icon h4 mr-1"
                  ></i>
                  <h6 class="m-0 y-2 d-inline">
                    <strong>
                      {{  dictionaryService.getTranslation(genderItem.claveTraduccion)  }}
                    </strong>
                  </h6>
                </button>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div class="col-12 col-md-8 col-lg-6">
          <ng-container *ngIf="service.genderSection; else items">
            <ng-container *ngFor="let category of service.genderSection.categoria">
              <a
                class="btn p-2 m-1 btn-secondary mx-2 d-block d-lg-inline-block my-1 "
                [routerLink]="['/admin/estilos/']"
                [queryParams]="{ category: category.tipo, gender: service.gender }"
                routerLinkActive="active"
              >
              {{  dictionaryService.getTranslation(category.claveTraduccion)  }}
              </a>
            </ng-container>
          </ng-container>
          <ng-template #items>
            <a
              *ngFor="let item of listItems"
              class="btn p-2 m-1 btn-secondary"
              [routerLink]="['/admin/estilos/']"
              routerLinkActive="active"
            >{{item}}</a>
          </ng-template>
        </div>

        <div class="col-lg-3 d-none d-lg-inline-block"></div>
      </div>

      <div class="row my-4 d-lg-none">
        <ng-container *ngIf="service.data">
          <ng-container *ngFor="let genderItem of service.data; index as i" >
            <div class="col">
              <button class="btn mr-1 "
                [routerLink]="['/admin/estilos/']"
                [queryParams]="{ gender: genderItem.nombre }"
                [ngClass]="service.gender === genderItem.nombre ? 'button-selected' : 'button-unselected'"
              >
              <i
                [ngClass]="genderIcon(genderItem.nombre)"
                class="d-inline icon h4 mr-1"
              ></i>
              <h6 class="m-0 y-2 d-inline">
                <strong>
                  {{  dictionaryService.getTranslation(genderItem.claveTraduccion)  }}
                </strong>
              </h6>
              </button>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="row my-5 justify-content-center">
        <ng-container *ngIf="service.categorySection; else List">
          <div
            class="col-12 col-md-6 col-lg-4 mt-2 mb-4"
            [ngClass]="bigDesktopService.bigDesktop ? 'col-xl-2' : 'col-xl-3'"
            *ngFor="let style of service.categorySection.clasificacion"
          >
            <h4 class="text-center">
              {{  dictionaryService.getTranslation(style.claveTraduccion)  }}
            </h4>
            <app-file-upload
              (dataFiles)="getFiles($event, service.categorySection.id, style.id)"
              [urlImage]="style.imagen"
            ></app-file-upload>
          </div>
        </ng-container>
    </div>

    <!-- Save Button -->
    <ng-container *ngIf="itemsToUpload">
      <button
        class="btn btn-secondary btn-mobile btn-block py-2 mb-4 "
        (click)="uploadFiles()"
      >
        <i class="fas fa-cloud-upload-alt mr-2 "></i>
        <strong class="d-inline">
          {{  dictionaryService.getTranslation(translation.textSave)  }}
        </strong>
      </button>
    </ng-container>

    <!-- Loading view -->
    <ng-template #List>
        <div
          class="col-12 col-md-6 col-lg-4 mt-2 mb-4"
          [ngClass]="bigDesktop ? 'col-xl-2' : 'col-xl-3'"
          *ngFor="let style of stylesList"
        >
          <app-file-upload></app-file-upload>
        </div>
    </ng-template>
  </div>
</app-layout>
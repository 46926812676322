<div class="input-group">
  <div
    class="input-group-prepend"
    (click)="search(queryModel.value)"
    style="cursor: pointer"
  >
    <span class="input-group-text"><i class="fas fa-search"></i></span>
  </div>
  <input
    type="text"
    class="form-control"
    #queryModel
    (keyup.enter)="search(queryModel.value)"
    placeholder="Buscar..."
  />
</div>

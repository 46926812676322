import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class BigDesktopService {
  public bigDesktop: boolean;

  isBigDesktop(): void {
    const desktop = window.matchMedia('(min-width: 1700px)');

    desktop.addEventListener('change', () => {
      this.bigDesktop = desktop.matches;
    });

    this.bigDesktop = desktop.matches;
  }
}

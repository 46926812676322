import { Injectable } from '@angular/core';

// Services
import { HttpService } from './http.service';
import { LoadingService } from './loading.service';
import { AlertService } from './alert.service';

import { environment } from '@enviroment';

@Injectable({
    providedIn: 'root'
})

export class UserTemsService {

    urlAPI = environment.api.userTerms;

    constructor(private httpServ: HttpService, 
                private loadServ: LoadingService,
                private alertServ: AlertService){}

    getUserTerms(){
        this.loadServ.show();
        return this.httpServ.get<any>(this.urlAPI.terms);
    }

    updateUserTerms(body:any){
        this.loadServ.show();
        this.httpServ.tempPut<string>(this.urlAPI.terms, body).subscribe(response => {
            if(response){
                this.alertServ.success(response).then(r => {
                    if(r.isConfirmed){
                        this.loadServ.hide();
                    }
                })
            }
        }, err => {
            this.loadServ.hide();
        })
    }
}
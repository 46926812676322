import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

// Components
import { MenuComponent } from '@shared/components/menu/menu.component';
import { LayoutComponent } from '@shared/components/layout/layout.component';
import { SearchComponent } from '@shared/components/search/search.component';
import { SidebarComponent } from '@shared/components/sidebar/sidebar.component';
import { PaginadorComponent } from '@shared/components/paginador/paginador.component';
import { FormErrorComponent } from '@shared/components/form-error/form-error.component';
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component';
import { TableDetailComponent } from '@shared/components/table-detail/table-detail.component';
import { LoadingComponent } from './loading/loading.component';
import { ButtonSaveComponent } from '@shared/components/button-save/button-save.component';
import { BlockedAccessComponent } from './blocked-access/blocked-access.component'

@NgModule({
  exports: [
    MenuComponent,
    LayoutComponent,
    SearchComponent,
    SidebarComponent,
    LoadingComponent,
    PaginadorComponent,
    FormErrorComponent,
    FileUploadComponent,
    TableDetailComponent,
    ButtonSaveComponent
  ],
  declarations: [
    MenuComponent,
    LayoutComponent,
    SearchComponent,
    LoadingComponent,
    SidebarComponent,
    PaginadorComponent,
    FormErrorComponent,
    FileUploadComponent,
    TableDetailComponent,
    ButtonSaveComponent,
    BlockedAccessComponent
  ],
  imports: [FormsModule, CommonModule, RouterModule]
})
export class SharedComponentModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

// Components
import { LoginComponent } from '@components/auth/login/login.component';

// Modules
import { AuthRoutingModule } from '@components/auth/auth-routing.module';
import { SharedComponentModule } from '@shared/components/shared.component.module';
import { SharedDirectiveModule } from '@shared/directives/shared.directive.module';

@NgModule({
  declarations: [LoginComponent],
  imports: [CommonModule, AuthRoutingModule, ReactiveFormsModule, SharedComponentModule, SharedDirectiveModule]
})
export class AuthModule { }

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  validateMatchPassword,
  validatePassword
} from '@shared/util/validator.util';

// Services
import { UserService } from '@shared/services/user.service';
import { AlertService } from '@shared/services/alert.service';
import { LanguageService } from '@shared/services/language.service';
import { DictonaryService } from '@shared/services/dictonary.service';

// Models
import { User } from '@shared/models/user.model';

// Interfaces
import { ITranslation } from '@shared/interface/translation.interface';

import { environment } from '@enviroment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public user: User;
  public idDictionary: number;
  public formPassword: FormGroup;
  public translation: ITranslation;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public languageService: LanguageService,
    public dictonaryService: DictonaryService
  ) {
    this.user = new User();
    this.user.getUserStore();

    this.formPassword = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, validatePassword()]],
      repeatNewPassword: [
        '',
        [Validators.required, validateMatchPassword('newPassword')]
      ]
    });

    this.languageService.getAllLanguages();
    this.translation = environment.language;
    this.idDictionary = this.user.language.id;
  }

  ngOnInit(): void {
  }

  public changeLang(idDictionary: string): void {
    if (this.idDictionary !== Number(idDictionary)) {
      this.alertService
        .confirmDialog('¿Esta seguro cambiar su idioma por default?')
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            this.userService.changeLanguage(Number(idDictionary), this.user.parseToObject())
          }
        });
    }
  }

  public changePassword(): void {
    if (this.formPassword.valid) {
      this.userService.changePassword(this.formPassword.value).subscribe(() => {
        this.formPassword.reset();
      });
    }
  }
}

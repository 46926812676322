import { Router } from '@angular/router'
import { Injectable } from '@angular/core'
import { environment } from '@enviroment'

// Services
import { HttpService } from '@shared/services/http.service'
import { AlertService } from '@shared/services/alert.service'
import { LoadingService } from '@shared/services/loading.service'

@Injectable({
  providedIn: 'root'
})

export class BrandLevelService {
  private path: any

  // Data levels
  public levels: any[]
  public category: object

  // Update levels
  public listOfLevelsToUpload: any[]
  private formData: FormData

  constructor(
    private httpService: HttpService,
    private alertService: AlertService,
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.path = environment.api.brandLevel
    this.levels = []
    this.category = {}
    this.listOfLevelsToUpload = []
  }

  public getCategories(categoryTipe: string) {
    if(categoryTipe) {
      this.category = this.levels.filter(category => category.tipo ===categoryTipe )[0]
    }
    else this.category = this.levels[0]
  }

  public getLevels(categoryTipe: string) {
    this.loadingService.show()
    return this.httpService.get<any>(this.path.levels)
      .subscribe((res: any) => {
        console.log(res)
        this.levels = res
        this.getCategories(categoryTipe)
        this.loadingService.hide()
      },
      err =>{
        this.alertService.error(err)
        this.loadingService.hide()
      })
  }

  newUpdateBrand(imagen: File, nivel: number):void {
    const newLevel = { imagen, nivel }
    this.listOfLevelsToUpload.push(newLevel)
    this.setFormData()
  }

  setFormData() {
    const formData = new FormData()
    this.listOfLevelsToUpload.map((level, index) => {
      formData.append(`niveles[${index}][imagen]`, level.imagen)
      formData.append(`niveles[${index}][nivel]`, level.nivel.toString())
    })
    this.formData = formData
  }

  public updateBrandLevel(categoryTipe: string):void {
    this.loadingService.show()
    this.httpService.put<any>(this.path.levels, this.formData )
      .subscribe((res: any) => {
        this.alertService.success(res).then(() => {
          this.listOfLevelsToUpload = []
          this.getLevels(categoryTipe)
        })
        this.loadingService.hide()
      },
      err =>{
        this.alertService.error(err)
        this.loadingService.hide()
      })
  }
}

<div class="row">
  <div class="col-12 col-sm-1 col-md-2 col-lg-2 col-xl-2">
    <span style="line-height: 3; font-size: 15px">Registros por página</span>
  </div>
  <div class="col-12 col-sm-1 col-md-2 col-lg-1 col-xl-1">
    <select
      class="form-control"
      [(ngModel)]="selectItems"
      (ngModelChange)="cambiarRegistrosPorPagina($event)"
      style="width: 70px"
    >
      <option>10</option>
      <option>25</option>
      <option>50</option>
    </select>
  </div>
  <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
    <span style="line-height: 3; font-size: 15px"
      >Mostrando {{ paginacion.startIndex + 1 }} al
      {{ paginacion.endIndex + 1 }} de {{ tamanioRespuesta }} registros</span
    >
  </div>
  <div
    class="col-12 col-sm-6 col-md-4 col-lg-5 col-xl-5"
    style="overflow: auto"
  >
    <ul class="pagination pagination-sm">
      <li class="page-item">
        <a class="page-link" (click)="primeraPagina()">Primero</a>
      </li>
      <ng-container *ngFor="let item of paginacion.pages">
        <li
          class="page-item"
          [ngClass]="{ active: paginacion.currentPage === item }"
        >
          <a class="page-link" (click)="obtenerInformacionPaginador(item)">{{
            item
          }}</a>
        </li>
      </ng-container>
      <li class="page-item">
        <a class="page-link" (click)="ultimaPagina()">Último</a>
      </li>
    </ul>
  </div>
</div>

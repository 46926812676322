import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

/// Modules
import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';

// Modules
import { AuthModule } from '@components/auth/auth.module';
import { AdminModule } from '@components/admin/admin.module';

// Services
import { HttpInterceptorService } from '@shared/interceptors/http-interceptor.service';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, AuthModule, AdminModule, HttpClientModule, ReactiveFormsModule],
  providers: [
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService
    }
  ]

})
export class AppModule {
}

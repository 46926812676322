<div id="table-detail">
  <div class="row align-items-center content-actions">
    <div
        class="col-12 col-sm-12 col-md-5 col-lg-7 btn-create-content"
        *ngIf="table.actions && table.actions.create"
    >
      <a
          class="btn btn-sm btn-dark"
          [routerLink]="[table.actions.create.url]"
      >
        <i class="fas fa-plus"></i>
        {{ dictionaryService.getTranslation(table.actions.create.text) }}
      </a>
    </div>
    <div
        class="col-12 col-sm-12 col-md-5 col-lg-4 search-content"
        *ngIf="table.actions && table.actions.search"
    >
      <app-search (query)="search($event)"></app-search>
    </div>
    <div class="col-12 col-sm-12 col-md-2 col-lg-1 btn-refresh">
      <button class="btn btn-sm btn-dark w-100" (click)="getAllList()">
        <i class="fas fa-sync-alt"></i>
      </button>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-hover">
      <thead class="thead-dark">
      <tr>
        <th class="text-center" *ngFor="let header of table.header">
          {{ dictionaryService.getTranslation(header.text) }}
        </th>
        <th class="text-center" *ngIf="table.actions">
          {{ dictionaryService.getTranslation(translation.textActionsTable) }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of data.lista">
        <td
            class="text-center align-middle"
            *ngFor="let header of table.header"
            [ngSwitch]="header.type"
        >
          <span *ngSwitchCase="typeData.DATE"> Date </span>
          <span *ngSwitchCase="typeData.MONEY"> money </span>
          <span *ngSwitchCase="typeData.IMAGEN">
              <img
                  [src]="data[header.key]"
                  *ngIf="data[header.key]"
                  class="img-content"
                  alt=""
              />
              <i class="far fa-image fa-9x" *ngIf="!data[header.key]"></i>
            </span>
          <span
              *ngSwitchCase="typeData.STATUS"
              class="badge"
              [ngClass]="{
                'badge-success': data[header.key] === 'ACTIVO',
                'badge-danger': data[header.key] === 'INACTIVO',
                'badge-danger': data[header.key] === 'PENDIENTE'
              }"
          >
              {{ data[header.key] }}
            </span>
          <span
            *ngSwitchCase="typeData.PLAN"
            class="badge"
            [ngClass]="{
                'badge-dark': data[header.key],
                'badge-light': !data[header.key]
              }"
          >
              {{ data[header.key] ? 'PRO' : 'NO PRO' }}
            </span>
          <span *ngSwitchDefault>
              {{ data[header.key] }}
            </span>
        </td>

        <td
            class="w-25 text-center align-middle"
            *ngIf="
              table.actions &&
              (table.actions.view || table.actions.edit || table.actions.delete)
            "
        >
            <span *ngIf="table.actions.view" class="d-inline-block mr-2">
              <a
                  class="btn btn-sm btn-secondary"
                  [routerLink]="[
                  table.actions.view.url,
                  data[table.actions.view.fieldKey]
                ]"
              >
                <i class="far fa-eye"></i>
              </a>
            </span>
          <span *ngIf="table.actions.edit" class="d-inline-block mr-2">
              <a
                  class="btn btn-sm btn-secondary"
                  [routerLink]="[
                  table.actions.edit.url,
                  data[table.actions.edit.fieldKey]
                ]"
              >
                <i class="fas fa-edit"></i>
              </a>
            </span>
          <span *ngIf="table.actions.delete" class="d-inline-block mr-2">
              <button
                  class="btn btn-sm btn-secondary"
                  (click)="delete(data[table.actions.edit.fieldKey])"
              >
                <i class="fas fa-trash-alt"></i>
              </button>
            </span>
        </td>
      </tr>

      <tr *ngIf="data.lista.length === 0">
        <td [attr.colspan]="getCountCells()">
          <p class="text-center text-danger">
            <i class="fas fa-exclamation-circle"></i>&nbsp;&nbsp;Sin registros
            disponibles
          </p>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <app-paginador
      *ngIf="data.lista.length > 0 && data.total > itemsPerPageInital"
      [lastPage]="data.ultimaPagina"
      [tamanioRespuesta]="data.total"
      [jsonData]="data.lista"
      [getInitialInfo]="getList"
      [customInitialPage]="customInitialPaginatorPage"
      [getActualPage]="getActualPaginatorPage"
      (numFilas)="getElementsByPage($event)"
      (enviaPaginaActual)="getPageCurrent($event)"
  ></app-paginador>
</div>

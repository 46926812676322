import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

// Services
import { StylesService } from '@shared/services/styles.service';
import { BigDesktopService } from '@shared/services/util/bigDesktop.service';
import { DictonaryService } from '@shared/services/dictonary.service';
import { ITranslation } from '@shared/interface/translation.interface';
import { environment } from '@enviroment';

@Component({
  selector: 'app-styles',
  templateUrl: './styles.component.html',
  styleUrls: ['./styles.component.scss']
})
export class StylesComponent implements OnInit {
  public sections: string[];
  public stylesList: string[];
  public itemsToUpload: boolean;
  public translation: ITranslation;

  constructor(
    private route: ActivatedRoute,
    public service: StylesService,
    public bigDesktopService: BigDesktopService,
    public dictionaryService: DictonaryService
  ) {
    this.stylesList = [
      'Classic',
      'Delicate',
      'Dramatic',
      'Creative',
      'Boho',
      'Delicate'
    ];
    this.sections = [
      'Shoes',
      'Blouse',
      'Pants',
      'Complement',
      'Accesory',
      'Wedding dress'
    ];
    this.translation = environment.language;
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.service.gender = params.get('gender');
      this.service.category = params.get('category');
      this.itemsToUpload = false;
      this.service.filterAll(true);
    });
    this.service.getStyles();
    this.bigDesktopService.isBigDesktop();
  }

  genderIcon(gender: string): string {
    switch (gender) {
      case 'Masculino':
        return 'fas fa-male';
      case 'Femenino':
        return 'fas fa-female';
      default:
        return 'fas fa-street-view';
    }
  }
  claveTraduccionGender(gender): string {
    switch (gender) {
      case 'Masculino':
        return 'gender_male';
      case 'Femenino':
        return 'gender_female';
      default:
        return '';
    }
  }

  getFiles = (files: any, categoryId: number, clasificationId: number) => {
    if (files && categoryId && clasificationId) {
      this.itemsToUpload = this.service.getFilesToUpload(
        files,
        categoryId,
        clasificationId
      );
    }
  };

  uploadFiles = () => {
    this.service.updateStyleImage().then(() => (this.itemsToUpload = false));
  };
}

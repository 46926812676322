import { ITranslation } from '@shared/interface/translation.interface';

export const language: ITranslation = {
  logout: 'cerrar_sesion',
  nameText: 'texto_nombre',
  profile: 'menu_mi_perfil',
  emailText: 'texto_correo',
  languageText: 'texto_idioma',
  passwordText: 'texto_contrasenia',
  informationText: 'texto_informacion',
  passwordOldText: 'texto_anterior_contrasenia',
  newBannerText: 'new_banner_text',
  editBannerText: 'edit_banner_text',
  adText: 'ad_text',
  textTo: 'text_to',
  textFrom: 'text_from',
  textDescription: 'text_description',
  periodText: 'period_text',
  brandLevelTitle: 'brand_level_title',
  textUploadFile: 'text_upload_file',
  textEdit: 'text_edit',
  textCancel: 'text_cancel',
  textSave: 'text_save',
  textCompany: 'text_company',
  textNewBrand: 'text_new_brand',
  textNewCompany: 'text_new_company',
  textCategory: 'text_category',
  stylesTitle: 'styles_title',
  colorimetryTitle: 'colorimetry_title',
  textNewUser: 'text_new_user',
  rol: 'rol',
  lastNameText: 'text_last_name',
  textFirstLastName: 'text_first_last_name',
  textSecondLastName: 'text_second_last_name',
  id: 'id',
  logo: 'logo',
  imagen: 'imagen',
  textNewGarment: 'text_new_garment',
  outfitRules: 'outfit_rules',
  textActionsTable: 'text_acciones_table',
  labelUserDetail: 'label_user_detail',
  labelName: 'label_name',
  labelMiddleName: 'label_middle_name',
  labelLastName: 'label_last_name',
  labelEmail: 'label_email',
  labelPassword: 'label_password',
  labelConfirmPassword: 'label_confirm_password',
  labelRol: 'label_rol',
  selectOption: 'label_selecion_option',
  genderText: 'clave_genero',
  womanText: 'woman_text',
  manText: 'man_text',
  eventType: 'tipo_evento',
  textStatus: 'text_estatus',
  bodyShape: 'catalogo_tipo_cuerpo',
  colorimetryText: 'menu_colorimetria',
  styleText: 'estilo_texto',
  brandLevelText: 'menu_nivel_marca',
  notificationsText: 'menu_notificaciones',
  userDetails: 'user_details',
  clientDetails: 'client_details',
  editAccount: 'edit_account',
  personalInfo: 'personal_info',
  dateOfBirth: 'date_of_birth',
  heightText: 'height',
  changePasswordText: 'change_password',
  newPasswordText: 'new_password',
  confirmPassowordText: 'confirm_password',
  enterNewPasswordText: 'enter_new_password',
  currentPasswordText: 'current_password',
  selectYours: 'select_yours',
  enterDate: 'enter_date',
  enterHeight: 'enter_height',
  metersText: 'meters_text',
  selectColor: 'select_color',
  honestAsPosible: 'honest_as_posible',
  selectAnOption: 'select_an_option',
  searchInGalery: 'search_in_galery',
  takePhoto: 'take_photo',
  myOutfit: 'my_outfit',
  clothespin: 'clothespin',
  planning: 'planning',
  myWishList: 'my_wish_list',
  wishList: 'whis_list',
  config: 'config',
  payment: 'payment',
  login: 'login',
  loginToYourAccount: 'login_to_your_account',
  loginFacebook: 'login_facebook',
  loginGoogle: 'login_google',
  loginApple: 'login_apple',
  signup: 'signup',
  signupSuccess: 'signup_success',
  createAccount: 'create_account',
  middleName: 'middle_name',
  exampleEmail: 'example_email',
  acceptTermsAndConditions: 'accept_terms_and_conditions',
  accept: 'accept',
  send: 'send',
  back: 'back',
  next: 'next',
  activeText: 'active_text',
  inactiveText: 'inactive_text',
  forget: 'forget',
  forgotYourPassword: 'forgot_your_password',
  passwordSentToEmail: 'password_sent_to_email',
  garmentNotFound: 'garment_not_found',
  searchText: 'search_text',
  wishListPrivateQuestion: 'wish_list_private_question',
  temperatureText: 'catalogo_temperatura',
  ubicationText: 'ubication_text',
  enterLocationText: 'enter_location_text',
  selectText: 'select_text',
  expandOutfitExperience: 'expand_outfit_experience',
  getProAccount: 'get_pro_account',
  addCreditCard: 'add_credit_card_text',
  editCreditCard: 'edit_credit_card_text',
  deleteCreditCard: 'delete_credit_card_text',
  error_500: 'error_500',
  catalogoEdad: 'catalogo_edad',
  proPlan: 'pro_plan'
};

import { Component, OnInit } from '@angular/core';

// Services
import { DictonaryService } from '@shared/services/dictonary.service';
import { UserTemsService } from '@shared/services/user-terms.service';
import { LoadingService } from '@shared/services/loading.service';

// Interface
import { IResponseUserTerms } from '@shared/interface/response.interface';

@Component({
    selector: 'app-terms-user',
    templateUrl: './user-terms.component.html',
    styleUrls: ['./user-terms.component.scss']
})

export class UserTermsComponent implements OnInit {

    model: any;
    idDictionary: number;
    responseUserTems: Array<IResponseUserTerms>;

    constructor(public dictionaryServ: DictonaryService, 
                public userServ: UserTemsService,
                private loadServ: LoadingService){}

    ngOnInit(){
        console.log(this.idDictionary)
        this.getUserTerms();
    }

    getUserTerms(){
        this.userServ.getUserTerms().subscribe(response => {
            if(response){
                this.responseUserTems = response;
                this.model = this.responseUserTems[0].terminos[0].texto;
                this.idDictionary = this.responseUserTems[0].terminos[0].id;
                this.loadServ.hide();
            }
        }, err => {
            this.loadServ.hide();
        })
    }

    updateUserTerms(){
        let body = {
            diccionario: this.idDictionary,
            texto: this.model
        }
        this.userServ.updateUserTerms(body);
    }

    getData(data:any, id:any){
        this.idDictionary = id;
        this.model = data;
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

// Components
import { ProfileComponent } from '@components/admin/profile/profile.component';
import { DashboardComponent } from '@components/admin/dashboard/dashboard.component';
import { OrderComponent } from '@components/admin/dashboard/order/order.component';
import { UserTermsComponent } from '@components/admin/user-terms/user-terms.component';
import { BrandLevelComponent } from './brand-level/brand-level.component';
import { ColorimetryComponent } from '@components/admin/colorimetry/colorimetry.component';
import { OutfitsComponent } from '@components/admin/outfits/outfits.component'
import { NotificationsComponent } from '@components/admin/Notifications/Notifications.component'

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'order/:id',
    component: OrderComponent
  },
  {
    path: 'marcas',
    loadChildren: () =>
      import('@components/admin/brand/brand.module').then(m => m.BrandModule)
  },
  {
    path: 'prendas',
    loadChildren: () =>
      import('@components/admin/garment/garment.module').then(
        m => m.GarmentModule
      )
  },
  {
    path: 'estilos',
    loadChildren: () => import('@components/admin/styles/styles.module').then(m=>m.StylesModule)
  },
  {
    path: 'colorimetria',
    component: ColorimetryComponent
  },
  {
    path: 'nivelmarca',
    component: BrandLevelComponent
  },
  {
    path: 'anuncios',
    loadChildren: () => import('@components/admin/Banners/banners.module').then(m => m.BannersModule)
  },
  {
    path: 'reglasoutfit',
    component: OutfitsComponent
  },
  {
    path: 'notifications',
    component: NotificationsComponent
  },
  {
    path: 'terminos',
    component: UserTermsComponent
  },
  {
    path: 'perfil',
    component: ProfileComponent
  },
  {
    path: 'usuarios',
    loadChildren: () =>
      import('@components/admin/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'clientes',
    loadChildren: () =>
      import('@components/admin/client/client.module').then(m => m.ClientModule)
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes), CommonModule]
})

export class AdminRoutingModule {}

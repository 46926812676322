import { Injectable } from '@angular/core';

import { environment } from '@enviroment';

// Services
import { HttpService } from '@shared/services/http.service';
import { LoadingService } from '@shared/services/loading.service';

// Interfaces
import { IDictonary } from '@shared/interface/dictonary.interface';
import { IResponseDictonary } from '@shared/interface/response.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DictonaryService {
  private path: any;

  public dictonary: Array<IDictonary>;
  private idLanguajeBS = new BehaviorSubject(null);
  public idLanguajeObs$ = this.idLanguajeBS.asObservable();

  constructor(
    private httpService: HttpService,
    private loadingService: LoadingService
  ) {
    this.dictonary = [];
    this.path = environment.api.language;
  }

  public getDictionary(idLang: number): void {
    this.loadingService.show();

    if (this.dictonary.length === 0) {
      const path: string = this.path.dictionary.replace('${id}', idLang);
      this.idLanguajeBS.next(idLang);

      this.httpService.get<IResponseDictonary>(path).subscribe(r => {
        if (r) {
          this.dictonary = r.diccionario;
        }
      });

      this.loadingService.hide();
    }
  }

  public getTranslation(key: string): string {
    if (this.dictonary.length > 0) {
      const dictonary = this.dictonary.find((d: IDictonary) => d.claveTraduccion === key);
      return dictonary ? dictonary.texto : null;
    }

    return null;
  }
}

import { Injectable } from '@angular/core'
import { environment } from '@enviroment'

// Services
import { HttpService } from '@shared/services/http.service'
import { LoadingService } from '@shared/services/loading.service'
import { AlertService } from '@shared/services/alert.service'

// Interfaces
import {
  ICatalogue,
  ICategoryOfCatelogue,
  IItemOfCatalogueToUpload
} from '@shared/interface/catalogue.interface'

@Injectable({
  providedIn: 'root'
})

export class StylesService {
  private path: any
  // sections
  public data: any
  public genderSection: any
  public categorySection: any
  // params
  public gender: string
  public category : string
  // Update
  private listOfFilesToUpload : Array<IItemOfCatalogueToUpload>
  private itemOfCatalogueToUpload : IItemOfCatalogueToUpload
  private formData: any

  constructor(
    private httpService: HttpService,
    public alertService: AlertService,
    private loadingService: LoadingService
  ) {
    this.path = environment.api.style
    this.itemOfCatalogueToUpload = {
      categoria: 0,
      imagen: null,
      id: 0
    }
    this.listOfFilesToUpload = []
  }

  async filterCategory() {
    if(this.genderSection) {
      if(this.category){
        this.categorySection = this.genderSection.categoria.filter(category => {
          return category.tipo === this.category
        })[0]
      }
      else this.categorySection = this.genderSection.categoria[0]
    }
  }

  public async filterGender() {
    if(this.data) {
      if(this.gender) {
        this.genderSection = this.data.filter(gender => {
          return gender.nombre === this.gender
        })[0]
      }
      else{
        this.genderSection = this.data[0]
        this.gender = this.genderSection.nombre
      }
    }
  }

  async filterAll(restore: boolean){
    if(this.data) {
      if(restore){
        this.genderSection = null
        this.categorySection = null
      }
      await this.filterGender()
      await this.filterCategory()
    }
  }

  public getStyles (): void {
    this.loadingService.show()
    this.httpService
      .get<Array<ICatalogue>>(`${this.path.allStyles}`)
      .subscribe(
      res => {
        this.data = res
        this.filterAll(false)
        this.loadingService.hide()
      },
      err =>{
        this.alertService.error(err)
        this.loadingService.hide()
      })
  }

  public getFilesToUpload = (imagen: any, categoria: number, id: number): boolean => {
    this.itemOfCatalogueToUpload = { categoria, imagen, id }
    this.listOfFilesToUpload.push(this.itemOfCatalogueToUpload)

    if(this.listOfFilesToUpload.length > 0) {
      const formData = new FormData()

      this.listOfFilesToUpload.map((item: IItemOfCatalogueToUpload, index: number) => {
        formData.append(`estilos[${index}][categoria]`, item.categoria.toString())
        formData.append(`estilos[${index}][imagen]`, item.imagen)
        formData.append(`estilos[${index}][id]`, item.id.toString())
      })

      this.formData = formData
      return true
    }
    else return false
  }

  public async updateStyleImage() {
    this.loadingService.show()
    this.httpService
      .put<Array<any>>(`${this.path.allStyles}`, this.formData)
      .subscribe(
      (res: any) => {
        if (res) this.alertService.success(res).then(() => {
          this.listOfFilesToUpload = []
          this.getStyles()
          this.loadingService.hide()
        })
      },
      err => {
        this.alertService.error(err)
        this.loadingService.hide()
      })
  }
}

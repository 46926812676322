// import { Observable } from 'rxjs';
// import { Router } from '@angular/router';
import { Injectable } from '@angular/core'
import { environment } from '@enviroment'

// Services
import { HttpService } from '@shared/services/http.service'
import { LoadingService } from '@shared/services/loading.service'
import { AlertService } from '@shared/services/alert.service'

// Interfaces
import {
    ICatalogue,
    ICategoryOfCatelogue,
    ISubCategoryOfCatalogue,
    ISubCategoryOfCategoryToUpload,
    IClasificationOfSubCategoryToUpload
} from '@shared/interface/catalogue.interface'

@Injectable({
  providedIn: 'root'
})

export class ColorimetryService {
  private path: any
  // Params
  public category:string
  public gender: string
  public subCategory: number
  // data
  public allData: any[]
  public genderSection: ICatalogue
  public subCategorySection: any
  public clasificationsSection: any
  // Upload
  private listOfItemsToUpload : Array<any>
  private subCategoryOfCategory: ISubCategoryOfCategoryToUpload
  private listOfsubCategoryOfCategory: Array<ISubCategoryOfCategoryToUpload>
  private clasificationOfSubCategory : IClasificationOfSubCategoryToUpload
  private listOfclasificationOfSubCategory: Array<IClasificationOfSubCategoryToUpload>
  private formData: any

  constructor(
    private httpService: HttpService,
    public alertService: AlertService,
    private loadingService: LoadingService
  ) {
    this.path = environment.api.colorimetry
    this.clasificationOfSubCategory = {
      categoria: 0,
      imagen: null,
      clasificacion: 0,
      subCategoria: 0
    }
    this.listOfItemsToUpload = []
    this.listOfsubCategoryOfCategory = []
    this.listOfclasificationOfSubCategory = []
  }

  // obtencioniendo datos de colorimetrias
  async filterClasifications() {
    if(this.subCategorySection) {
      if(this.subCategory){
        this.clasificationsSection = this.subCategorySection.subcategorias.filter(
          (clasification: ISubCategoryOfCatalogue) => {
            return clasification.id === this.subCategory
          })[0]
      }
      else this.clasificationsSection = this.subCategorySection.subcategorias[0]
    }
  }

  async filterCategory() {
    if(this.genderSection){
      if(this.category){
        this.subCategorySection = this.genderSection.categoria.filter(
          (category: ICategoryOfCatelogue) => category.tipo === this.category)[0]
      }
      else this.subCategorySection = this.genderSection.categoria[0]
    }
  }

  async filterGender() {
    if(this.allData) {
      if(this.gender) {
        this.genderSection = this.allData.filter((section: ICatalogue) => {
          return section.nombre === this.gender
        })[0]
      }
      else  {
        this.genderSection = this.allData[0]
        this.gender = this.genderSection.nombre
      }
    }
  }

  async filterAll(restore: boolean){
    if(restore){
      this.genderSection = null
      this.subCategorySection = null
      this.clasificationsSection = null
    }
    await this.filterGender()
    await this.filterCategory()
    await this.filterClasifications()
  }

  public getColorimetries() {
    this.loadingService.show()
    return this.httpService
      .get<Array<ICatalogue>>(`${this.path.colorimetries}`)
      .subscribe(res => {
        this.allData = res
        this.filterAll(false)
        this.loadingService.hide()
      },
      err => {
        this.alertService.error(err)
        this.loadingService.hide()
      })
  }
  // obtencioniendo datos de colorimetrias


  // Actualizaciones de subcategorias y clasificaciones
  public getFilesToUploadSubCategory(imagen: any, id: number): void {
    this.subCategoryOfCategory = { imagen, id}
    this.listOfsubCategoryOfCategory.push(this.subCategoryOfCategory)
    this.setFormData()
  }

  public getFilesToUploadClasification = (
      imagen: any,
      clasificacion: number,
      subCategoria: number,
      categoria: number
    ): void  => {

    this.clasificationOfSubCategory = {
      categoria,
      imagen,
      clasificacion,
      subCategoria
    }
    this.listOfclasificationOfSubCategory.push(this.clasificationOfSubCategory)
    this.setFormData()
  }

  thereAreItemsToUpload(): boolean {
    this.listOfItemsToUpload = [
      ...this.listOfsubCategoryOfCategory,
      ...this.listOfclasificationOfSubCategory
    ]
    if(this.listOfItemsToUpload.length > 0) return true
    else return false
  }

  setFormData() {
    const formData = new FormData()

    if(this.listOfsubCategoryOfCategory.length > 0) {
      this.listOfsubCategoryOfCategory.map(
        (item: ISubCategoryOfCategoryToUpload, index: number) => {
        formData.append(`categorias[${index}][imagen]`, item.imagen)
        formData.append(`categorias[${index}][id]`, item.id.toString())
      })
    }
    if(this.listOfclasificationOfSubCategory.length > 0) {
      this.listOfclasificationOfSubCategory.map(
        (item: IClasificationOfSubCategoryToUpload, index: number) => {
        formData.append(`subcategorias[${index}][categoria]`, item.categoria.toString())
        formData.append(`subcategorias[${index}][imagen]`, item.imagen)
        formData.append(`subcategorias[${index}][clasificacion]`, item.clasificacion.toString())
        formData.append(`subcategorias[${index}][subcategoria]`, item.subCategoria.toString())
      })
    }

    this.formData = formData
  }

// Peticion update
  public updateSubCategoryAndClasification() {
    this.loadingService.show()
    this.httpService
      .put<Array<any>>(`${this.path.colorimetries}`, this.formData)
      .subscribe(
      (res: any) => {
        if (res){
          this.listOfItemsToUpload = []
          this.listOfsubCategoryOfCategory = []
          this.listOfclasificationOfSubCategory = []
          this.alertService.success(res).then(() => this.getColorimetries())
          this.loadingService.hide()
        }
      },
      err => {
        this.alertService.error(err)
        this.loadingService.hide()
      })
  }
}

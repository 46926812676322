import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { environment } from '@enviroment';

// Service
import { AuthService } from '@shared/services/auth.service';
import { HttpService } from '@shared/services/http.service';
import { AlertService } from '@shared/services/alert.service';
import { LoadingService } from '@shared/services/loading.service';
import { DictonaryService } from '@shared/services/dictonary.service';

// Interfaces
import { IAuth } from '@shared/interface/auth.interface';
import { IMenu } from '@shared/interface/sidebar.interface';
import { IUser, IUserLogin } from '@shared/interface/user.interface';
import { IGarment } from '@shared/interface/garment.interface';
import { IRol } from '@shared/interface/rol.interface';

import {
  IResponseConfig,
  IResponseGarment,
  IResponseTable
} from '@shared/interface/response.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private path: any;

  public user: IUser;
  public menu: Array<IMenu>;
  public roles: Array<IRol>;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private authService: AuthService,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private dictonaryService: DictonaryService
  ) {
    this.roles = [];
    this.menu = [];
    this.path = environment.api.user;
  }

  public login(user: IUserLogin): void {
    this.httpService.post<IAuth>(this.path.auth, user).subscribe(response => {
      if (response) {
        this.authService.saveToken(response);
        this.router.navigateByUrl('/admin/dashboard').then();
      }
    });
  }

  public config(): void {
    if (!this.menu || !this.user) {
      this.loadingService.show();

      this.httpService
        .get<IResponseConfig>(this.path.me)
        .subscribe(response => {
          if (response) {
            console.log('response', response)
            this.menu = response.menu;
            this.user = response.usuario;

            localStorage.setItem('user', JSON.stringify(response.usuario));
            const idLanguage = this.user.idioma
              ? this.user.idioma.id
              : environment.idLangugage;

            this.dictonaryService.getDictionary(idLanguage);
          }

          this.loadingService.hide();
        });
    }
  }

  public logout(): void {
    this.httpService.get<string>(this.path.logout).subscribe(r => {
      if (r) {
        localStorage.clear();
        window.location.reload();
      }
    });
  }

  public changePassword({
    oldPassword,
    newPassword,
    repeatNewPassword
  }): Observable<void> {
    this.loadingService.show();

    const body = {
      contrasenia: newPassword,
      contrasenia_anterior: oldPassword,
      confirmacion_contrasenia: repeatNewPassword
    };

    return new Observable(observer => {
      this.httpService
        .post<string>(this.path.changePassword, body)
        .subscribe(r => {
          if (r) {
            this.alertService.success(r).then();
          }

          this.loadingService.hide();
          observer.next();
        });
    });
  }

  public changeLanguage(idLanguage: number, user: IUser): void {
    this.loadingService.show();

    this.httpService
      .put<string>(this.path.changeLanguage, { idioma: idLanguage })
      .subscribe(response => {
        if (response) {
          user.idioma.id = idLanguage;
          this.dictonaryService.dictonary = [];
          this.alertService.success(response).then();
          this.dictonaryService.getDictionary(idLanguage);
          localStorage.setItem('user', JSON.stringify(user));
        }
        this.loadingService.hide();
      });
  }

  public getList(
    page: number,
    items: number,
    filter?: string
  ): Observable<IResponseTable<Array<any>>> {
    const params = { pagina: page, limite: items };

    if (filter) {
      params['nombre'] = `${filter}`;
    }

    return this.httpService.get<IResponseTable<Array<any>>>(
      this.path.allUsers,
      { params }
    );
  }

  public delete(id: number): Observable<string> {
    return this.httpService.delete<string>(`${this.path.deleteUser}/${id}`);
  }

  public getUser(id: number) {
    return this.httpService.get<IUser>(`${this.path.user}/${id}`);
  }

  public updateUser(user: any, id: number): void {
    this.loadingService.show();
    this.httpService
      .put<string>(`${this.path.updateUser}/${id}`, user)
      .subscribe(response => {
        if (response) {
          this.alertService.success(response).then(res => {
            if (res.isConfirmed) {
              this.router.navigateByUrl('/admin/usuarios').then();
            }
          });
        }
        this.loadingService.hide();
      });
  }

  public save(body: any): void {
    this.loadingService.show();
    this.httpService
      .post<string>(`${this.path.saveUser}`, body)
      .subscribe(response => {
        if (response) {
          this.alertService.success(response).then(res => {
            if (res.isConfirmed) {
              this.router.navigateByUrl('/admin/usuarios').then();
            }
          });
        }
        this.loadingService.hide();
      });
  }

  public getRoles(): void {
    this.loadingService.show();

    this.httpService.get<Array<IRol>>(this.path.roles).subscribe(response => {
      this.roles = response;
      this.loadingService.hide();
    });
  }
}

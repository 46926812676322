import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

// Services
import { IAuth } from '@shared/interface/auth.interface';
import { AuthService } from '@shared/services/auth.service';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SecurityGuardGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    // Se verifcia si el usuario tiene una sesion activa en el navegador
    if (this.authService.isAuthenticated()) {
      // Se recupera el token
      const token: IAuth = this.authService.getToken();

      // Se obtiene la fecha actual
      const fechaActual = new Date(moment().format());

      // Se da formato a la fecha de expiracion
      const fechaExpiracion = new Date(moment(token.expiresAt).format());

      // Se verifica la fecha de expiracion
      if (fechaExpiracion >= fechaActual) {
        return new Observable<boolean>(r => r.next(true));
      }
    }

    this.authService.removeStorage(); // Se limpia la sesion
    this.router.navigateByUrl('/auth').then(); // Se redireciona al login
    return new Observable<boolean>(r => r.next(false));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputErrorDirective } from './input-error.directive';

@NgModule({
  imports: [ CommonModule ],
  exports: [InputErrorDirective],
  declarations: [InputErrorDirective],
})
export class SharedDirectiveModule { }

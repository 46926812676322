<app-layout>
  <h3>Detalles de la orden {{order_id}}</h3>
  <span class="badge badge-warning" *ngIf="order != null && order.status_id == 2">Pendiente de verificación</span>
  <span class="badge badge-info" *ngIf="order != null && order.status_id == 3">Cobrado a cliente</span>

  <span class="badge badge-secondary" *ngIf="order != null && order.status_id == 4">Pendiente de pago del cliente</span>
  <span class="badge badge-success" *ngIf="order != null && order.status_id == 5">Completada</span>
  <br><br>
  <div class="cotainer-fluid" *ngIf="order != null">
    <div class="row">
      <div class="col-md-7">
        <div class="card">
          <table class="table">
            <tbody *ngFor="let product of products">
              <tr>
                <th scope="row" rowspan="2" style="width: 105px;"><img src="{{product.product_image}}" alt=""></th>
                <td>
                  <b>Talla:</b> {{product.size}} <br>
                  <b>Cantidad:</b> {{product.quantity}}
                </td>
              </tr>
              <tr *ngIf="product.status_id == 1">
                <td>
                  <button class="btn btn-info" (click)="getStoreLink(product.product_id)">Validar en tienda</button>
                </td>
                <td>
                  <button class="btn btn-success" (click)="updateProduct(product.product_id, product.size, order.id, 2)">Disponible</button>
                  <button class="btn btn-outline-danger" (click)="updateProduct(product.product_id, product.size, order.id, 3)">No disponible</button>
                </td>
              </tr>
              <tr *ngIf="product.status_id > 2">
                <td colspan="2" *ngIf="product.status_id == 2"><span class="text-info">Disponible</span> Pago de cliente pendiente</td>
                <td *ngIf="product.status_id == 3"><span class="text-danger">No disponible</span></td>
              </tr>
              <tr *ngIf="order.status_id == 3 && product.status_id == 2">
                <td>
                  <button class="btn btn-info" (click)="getStoreLink(product.product_id)">Adquirir en tienda</button>
                </td>
                <td>
                  <button class="btn btn-success" (click)="updateProduct(product.product_id, product.size, order.id, 4)">Confirmar compra</button>
                </td>
              </tr>
              <tr *ngIf="product.status_id == 4">
                <td colspan="2"><span class="text-success">Adquirida</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-5">
        <div class="card">
          <div class="card-body">
            <h4><b>Detalles de la compra</b></h4>
            <hr>
            <div>
              <b>Datos de cliente:</b><br>
              Nombre: {{order.first_name}} {{order.middle_name}} {{order.last_name}} <br>
              Correo: <a href="mailto:{{order.email}}">{{order.email}}</a> <br>
              Teléfono: <a href="tel:+{{order.phone}}">{{order.phone}}</a> <br>
              <hr>
              <b>Dirección:</b><br>
              {{order.street}} {{order.number_ext}}, Col. {{order.colony}}, {{order.city}}, CP {{order.post_code}}
              <hr *ngIf="order.status_id > 2">
              <b *ngIf="order.status_id > 2">ID de cobro Stripe:</b><br>
              <span *ngIf="order.status_id > 2">{{order.payment_a}}</span> 
            </div>
          </div>
        </div><br>
        <h6><b>Total de la compra: </b>${{total}} MXN</h6>
        <button *ngIf="order.status_id == 2 && total > 0 && paying == false;" class="btn w-auto btn-outline-success" (click)="setPayment()">Cobrar a cliente</button>
        <form #paymentForm="ngForm" (ngSubmit)="savePayment()" *ngIf="order.status_id == 2 && total > 0 && paying == true;">
          <input type="text" class="form-control" required [(ngModel)]="payment_id" placeholder="ID de pago en Stripe" [ngModelOptions]="{standalone: true}">
          <button class="btn btn-success w-100">Guardar ID y continuar</button>
        </form>
        <button *ngIf="order.status_id == 3" class="btn w-auto btn-outline-success" (click)="confirmClient()">Confirmar a cliente</button>
      </div>
    </div>
  </div>
</app-layout>
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private http: HttpClient) {}

  upload(formData): Observable<HttpEvent<any>> {
    return this.http.post('https://file.io/', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  test(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    return this.http.post<any>('https://file.io/', formData, {
      reportProgress: true,
      responseType: 'json'
    });
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Guards
import { SecurityGuardGuard } from '@shared/guards/security-guard.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('@components/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'admin',
    canActivate: [SecurityGuardGuard],
    loadChildren: () =>
      import('@components/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '**',
    redirectTo: 'admin'
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'admin'
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule {}

<app-layout>
  <h3>Ordenes de ventas</h3>
  <table class="table">
    <thead class="thead-dark text-center">
      <tr>
        <th scope="col"># de orden</th>
        <!--th scope="col">Nombre de cliente</th>
        <th scope="col">Prendas</th>
        <th scope="col">Fecha de compra</th-->
        <th scope="col">Status</th>
        <th scope="col">Opciones</th>
      </tr>
    </thead>
    <tbody>
      <tr class="text-center selectable" *ngFor="let order of orders">
        <th scope="row">{{order.id}}</th>
        <td *ngIf="order.status_id == 2"><span class="badge badge-warning">Pendiente de verificación</span></td>
        <td *ngIf="order.status_id == 3"><span class="badge badge-info">Cobrado a cliente</span></td>
        <td *ngIf="order.status_id == 4"><span class="badge badge-secondary">Pendiente de pago</span></td>
        <td *ngIf="order.status_id == 5"><span class="badge badge-success">Completada</span></td>
        <td><button class="btn btn-primary" (click)="showVenta(order.id)">Ver orden</button></td>
      </tr>
      <tr *ngIf="orders == null">
        <td colspan="3" class="text-center"><b>No hay compras</b></td>
      </tr>
    </tbody>
  </table>
</app-layout>
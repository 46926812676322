import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { PaginationUtilService } from '@shared/services/util/pagination.util.service';

@Component({
  selector: 'app-paginador',
  styleUrls: ['./paginador.component.scss'],
  templateUrl: 'paginador.component.html'
})
export class PaginadorComponent implements OnInit, OnChanges {
  @Input() public lastPage: any;
  @Input() public tamanioRespuesta: any;
  @Input() public jsonData: any;
  @Input() public getPaginatorInfo = true;
  @Input() public customInitialPage: number | null = null;
  @Input() public getActualPage: (actualPage: number) => void | null = null;
  @Output() numFilas = new EventEmitter();
  @Output() enviaPaginaActual = new EventEmitter();
  @Output() dataSource = new EventEmitter();

  public paginaActual: number = 1;
  public selectItems: any = 10;
  public paginacion: any = {};

  constructor(private utileriasServ: PaginationUtilService) {
    this.lastPage = 0;
  }

  ngOnInit() {
    if (this.getPaginatorInfo) {
      const initialPage = this.customInitialPage || 1;
      this.obtenerInformacionPaginador(initialPage);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tamanioRespuesta) {
      this.obtenerInformacionPaginador(this.customInitialPage || 1);
    }

    if (changes && changes.customInitialPage) {
      let currentPage = changes.customInitialPage.currentValue;
      if (currentPage) {
        this.paginacion = this.utileriasServ.getPager(
          this.tamanioRespuesta,
          currentPage,
          this.selectItems
        );
      }
    }
  }

  cambiarRegistrosPorPagina(event: any) {
    this.numFilas.emit(event);
    this.paginacion = this.utileriasServ.getPager(
      this.tamanioRespuesta,
      this.customInitialPage || this.paginaActual,
      this.selectItems
    );
  }

  primeraPagina() {
    this.paginaActual = 1;
    this.obtenerInformacionPaginador(1);
  }

  ultimaPagina() {
    this.paginaActual = this.lastPage;
    this.obtenerInformacionPaginador(this.lastPage);
  }

  obtenerInformacionPaginador(paginaActual: any) {
    if (this.getActualPage) {
      this.getActualPage(paginaActual);
    }
    this.paginaActual = paginaActual;
    this.enviaPaginaActual.emit(paginaActual);
    this.paginacion = this.utileriasServ.getPager(
      this.tamanioRespuesta,
      paginaActual,
      this.selectItems
    );
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@enviroment';
// Service
import { HttpService } from '@shared/services/http.service';
import { LoadingService } from '@shared/services/loading.service';
// Interface
import {
  IResponseGarment,
  IResponseTable
} from '@shared/interface/response.interface';
import { AlertService } from '@shared/services/alert.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { FormArray, FormControl } from '@angular/forms';
import { IGarment } from '@shared/interface/garment.interface';

@Injectable({
  providedIn: 'root'
})
export class GarmentService {
  private path: any;

  public catalogs: IResponseGarment;

  constructor(
    private httpService: HttpService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.path = environment.api.garment;

    this.catalogs = {
      generos: [],
      companias: [],
      categorias: [],
      tipoEventos: [],
      colores: [],
      estampados: []
    };
  }

  public getList(
    page: number,
    items: number,
    filter?: any
  ): Observable<IResponseTable<Array<any>>> {
    let params = { pagina: page, limite: items };
    // console.log('params ==>', params)
    if (filter) {
      if (typeof filter == 'object') {
        params = { ...params, ...filter };
      } else {
        params['nombre'] = `${filter}`;
      }
    }
    return this.httpService.get<IResponseTable<Array<any>>>(
      this.path.allGarments,
      { params }
    );
  }

  public delete(id: number): Observable<string> {
    return this.httpService.delete<string>(`${this.path.deleteGarment}/${id}`);
  }

  public getCatalogs(): Observable<IResponseGarment> {
    if (
      this.catalogs.generos.length === 0 &&
      this.catalogs.colores.length === 0
    ) {
      return this.httpService.get<IResponseGarment>(this.path.catalogs);
    }

    return new Observable<IResponseGarment>(observable =>
      observable.next(this.catalogs)
    );
  }

  public getFillCatalogs(
    colors?: FormArray,
    eventType?: FormArray,
    action = () => {}
  ) {
    this.loadingService.show();
    return this.getCatalogs()
      .pipe(
        map(response => {
          if (response) {
            if (colors) {
              response.colores.forEach(() => colors.push(new FormControl()));
            }

            if (eventType) {
              response.tipoEventos.forEach(() =>
                eventType.push(new FormControl())
              );
            }
            return response;
          }
        })
      )
      .subscribe(response => {
        if (response) {
          this.catalogs = response;
        }
        this.loadingService.hide();
        action();
      }, console.error);
  }

  public getColorsSelected(colors: Array<boolean>) {
    return colors
      .map((value, index) => (value ? this.catalogs.colores[index].id : null))
      .filter(v => v != null);
  }

  public getEventTypeSelected(eventsType: Array<boolean>) {
    return eventsType
      .map((value, index) =>
        value ? this.catalogs.tipoEventos[index].id : null
      )
      .filter(v => v != null);
  }

  public save(body: any): void {
    this.loadingService.show();
    this.httpService
      .post<string>(`${this.path.garment}`, body)
      .subscribe(response => {
        if (response) {
          this.alertService.success(response).then(res => {
            if (res.isConfirmed) {
              this.router.navigateByUrl('/admin/prendas').then();
            }
          });
        }
        this.loadingService.hide();
      });
  }

  public updateGarment(company: any, id: number): void {
    this.loadingService.show();
    this.httpService
      .put<string>(`${this.path.putGarment}/${id}`, company)
      .subscribe(response => {
        if (response) {
          this.alertService.success(response).then(res => {
            if (res.isConfirmed) {
              // this.router.navigateByUrl('/admin/prendas').then();
            }
          });
        }
        this.loadingService.hide();
      });
  }

  public getGarment(id: number) {
    return this.httpService.get<IGarment>(`${this.path.getGarment}/${id}`);
  }
}

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

// Services
import { AuthService } from '@shared/services/auth.service';

// Interfaces
import { IAuth } from '@shared/interface/auth.interface';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.headers.get('No-Auth') == "True"){
      return next.handle(request.clone());
    } 

    const token: IAuth = this.authService.getToken();
    
    if (request.url.indexOf('/auth') !== -1) {
      if (token) {
        request = request.clone({
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + token.accessToken
          )
        });
      }
    }

    return next.handle(request);
  }
}

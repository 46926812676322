<app-layout>
  <div class="container-fluid">
    <h2 class="text-center">
      {{  dictionaryService.getTranslation(translation.colorimetryTitle)  }}
    </h2>
        <div class="text-center">
          <div class="row mt-1 justify-content-center">
            <div class="col-md-2 col-lg-3 d-none d-md-inline-block">
              <div class="justify-content-start text-left ">
                <ng-container *ngIf="service.allData">
                  <ng-container *ngFor="let genderItem of service.allData; index as i" >
                    <button class="btn button-to-select d-none d-lg-inline-block"
                      [routerLink]="['/admin/colorimetria/']"
                      [queryParams]="{ gender: genderItem.nombre }"
                      [ngClass]="service.gender === genderItem.nombre ? 'button-selected' : 'button-unselected'"
                    >
                      <i
                        [ngClass]="genderIcon(genderItem.nombre)"
                        class="d-inline icon h4 mr-1"
                      ></i>
                      <h6 class="m-0 y-2 d-inline">
                        <strong>
                          {{  dictionaryService.getTranslation(genderItem.claveTraduccion)  }}
                        </strong>
                      </h6>
                    </button>
                  </ng-container>
                </ng-container>
              </div>
            </div>

            <div class="col-12 col-md-8 col-lg-6">
              <ng-container *ngIf="service.genderSection">
                <ng-container *ngFor="let category of service.genderSection.categoria">
                  <a
                    class="btn p-2 m-1 btn-secondary mx-2 d-block d-md-inline-block my-1 "
                    [routerLink]="['/admin/colorimetria/']"
                    [queryParams]="{ category: category.tipo, gender: service.gender }"
                    routerLinkActive="active"
                  >
                    {{  dictionaryService.getTranslation(category.claveTraduccion)  }}
                  </a>
                </ng-container>
              </ng-container>
            </div>
            <div class="col-2 col-lg-3 d-none d-md-inline-block"></div>
          </div>

          <div class="row my-4 d-lg-none">
            <ng-container *ngIf="service.allData">
              <ng-container *ngFor="let genderItem of service.allData; index as i" >
                <div class="col">
                  <button class="btn mr-1 "
                    [routerLink]="['/admin/colorimetria/']"
                    [queryParams]="{ gender: genderItem.nombre }"
                    [ngClass]="service.gender === genderItem.nombre ? 'button-selected' : 'button-unselected'"
                  >
                    <i
                      [ngClass]="genderIcon(genderItem.nombre)"
                      class="d-inline icon h4 mr-1"
                    ></i>
                    <h6 class="m-0 y-2 d-inline">
                      <strong>
                        {{  dictionaryService.getTranslation(genderItem.claveTraduccion)  }}
                      </strong>
                    </h6>
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

      <div class="row mt-5 mb-2 justify-content-center">
        <ng-container *ngIf="service.subCategorySection; else List">
          <div
            class="col-12 col-md-6 col-lg-4 mb-1"
            [ngClass]="bigDesktopService.bigDesktop ? 'col-xl-2' : 'col-xl-3'"
            *ngFor="let subCategory of service.subCategorySection.subcategorias"
          >
            <div
              (click)="getClasification(subCategory.id)"
              class="pointer"
              [ngClass]="selectedCategory(subCategory.id)"
            >
              <app-file-upload
                (dataFiles)="getFilesOfSubCategory($event, subCategory.id)"
                [urlImage]="subCategory.imagen"
              ></app-file-upload>
            </div>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="service.clasificationsSection">
        <div class="row my-5 justify-content-center">
          <div
            class="col-12 col-md-6 col-lg-4 mt-4"
            [ngClass]="bigDesktopService.bigDesktop ? 'col-xl-2' : 'col-xl-3'"
            *ngFor="let clasificacion of service.clasificationsSection.clasificaciones"
          >
            <h4 class="text-center">
              {{  dictionaryService.getTranslation(clasificacion.claveTraduccion)  }}
            </h4>
            <app-file-upload
              (dataFiles)="getFilesOfClasification($event, clasificacion.id, service.subCategorySection.id, service.genderSection.id)"
              [urlImage]="clasificacion.imagen"
            ></app-file-upload>
          </div>
        </div>
      </ng-container>

      <!-- Save button-->
      <ng-container *ngIf="itemsToUpload">
        <button
          class="btn btn-secondary button btn-block py-2 mb-4 "
          (click)="update()"
        >
          <i class="fas fa-cloud-upload-alt"></i>
          <strong class="mr-2 d-inline">
            {{  dictionaryService.getTranslation(translation.textSave)  }}
          </strong>
        </button>
      </ng-container>

      <!-- List -->
      <ng-template #List>
        <div class="row my-3 justify-content-center">
          <div
            class="col-12 col-md-6 col-lg-4 mt-2 mb-4"
            [ngClass]="bigDesktop ? 'col-xl-2' : 'col-xl-3'"
            *ngFor="let i of list"
          >
            <app-file-upload></app-file-upload>
          </div>
        </div>
      </ng-template>
  </div>
</app-layout>

import { Component, Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
@Injectable()
export class DashboardComponent implements OnInit {

  public orders = null;

  constructor(
    public _http: HttpClient, private _router: Router
  ) { }

  ngOnInit(): void {
    this.getOrders().subscribe(
      response => {
        this.orders = response.orders;
      },
      error => {
        console.log(error);
      }
    );
  }

  getOrders(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let json = {option: 2}

    return this._http.post('https://shop.outfit365.app/api-venta/service/order', json, {headers: headers});
  }

  showVenta(id){
    console.log(id);
    localStorage.setItem("orderId", id);
    this._router.navigate(['/admin/order', id]);
  }

}

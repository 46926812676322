import { Injectable } from '@angular/core';

import { environment } from '@enviroment';

// Services
import { HttpService } from '@shared/services/http.service';
import { LoadingService } from '@shared/services/loading.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly path: string;

  constructor(
    private httpService: HttpService,
    private loadingService: LoadingService
  ) {
    this.path = environment.api.client.pushNotification;
  }

  public sentPushNotifications(request: {
    gender: number;
    style: number;
    bodyShape: number;
    colorimetry: number;
    body: string;
    title: string;
  }): void {
    this.loadingService.show();
    this.httpService
      .post<void>(this.path, {
        ...request,
        notification: {
          title: request.title,
          body: request.body
        }
      })
      .subscribe(value => {
        this.loadingService.hide();
      });
  }
}

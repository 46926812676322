<div id="container-login" class="w-100 vh-100">
  <div class="login-form position-relative w-100 vh-100">
    <div class="content-form position-absolute">
      <div class="logo">
        <img [src]="images.logo" alt="outfit365">
      </div>
      <form [formGroup]="formLogin" (ngSubmit)="login()">
        <div class="form-group">

          <div class="input-group">

            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fas fa-at"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              name="email"
              placeholder="Ingrese su correo"
              formControlName="correo"
              required
              appInputError
              [field]="formLogin.get('correo')"
            />
          </div>

          <app-form-error [control]="formLogin.get('correo')"></app-form-error>
        </div>
        <div class="form-group">

          <div class="input-group">

            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fas fa-key"></i>
              </span>
            </div>
            <input
              type="password"
              class="form-control"
              name="password"
              formControlName="password"
              placeholder="Ingrese su contraseña"
              required
              appInputError
              [field]="formLogin.get('password')"
            />
          </div>

          <app-form-error [control]="formLogin.get('password')"></app-form-error>
        </div>

        <div class="form-group text-center container-btn">
          <button type="submit" class="btn btn-dark btn-block"
                  [disabled]="formLogin.invalid">Ingresar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

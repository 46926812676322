import { AbstractControl } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit {

  @Input() public control: AbstractControl;
  @Input() public submitted: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }
}

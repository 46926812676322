<nav id="sidebar" class="bg-dark">
  <div id="profile">
    <div id="profile-container" class="text-white text-center w-100">
      <i class="fas fa-user"></i>
      <p>
        {{ userService.user ? userService.user.nombre : '' }}
      </p>
      <!--<p class="rol">{{ userService.user.tipo }}</p>-->
    </div>
  </div>

  <div class="menu">
    <ul class="list-unstyled">

      <li>
        <a routerLink="/admin/dashboard"
           routerLinkActive="active"
           class="w-100 d-block text-white text-decoration-none">
          <i class="fas fa-home"></i>
          <span>Dashboard</span>
        </a>
      </li>

      <li *ngFor="let menu of userService.menu; let idx = index"
          [id]="isSubMenu(menu) ? 'dropdown-' + idx : 'menu-' + idx"
          [ngClass]="{'dropdown': isSubMenu(menu), 'open': isOpen(menu.ruta) && isSubMenu(menu)}">

        <a routerLink="{{ menu.ruta }}"
           routerLinkActive="active"
           *ngIf="!isSubMenu(menu)"
           class="w-100 d-block text-white text-decoration-none">
          <i class="{{ menu.icono }}"></i>
          <span>{{ dictonaryService.getTranslation(menu.claveTraduccion) }}</span>
        </a>

        <a href="javascript:void(0)" *ngIf="isSubMenu(menu)"
           class="w-100 d-block text-white text-decoration-none"
           (click)="open('#dropdown-' + idx)">
          <i class="{{ menu.icono }}"></i>
          <span>{{ dictonaryService.getTranslation(menu.claveTraduccion) }}</span>
          <span class="close-menu">
            <i class="fas fa-angle-right"></i>
          </span>
          <span class="open-menu">
            <i class="fas fa-angle-down"></i>
          </span>
        </a>

        <div class="dropdown-content w-100" *ngIf="isSubMenu(menu)">
          <a *ngFor="let subMenu of menu.submenu;"
             routerLink="{{ subMenu.ruta }}"
             routerLinkActive="active"
             class="w-100 d-block text-white text-decoration-none">
            <i class="{{ subMenu.icono }}"></i>
            <span>{{ dictonaryService.getTranslation(subMenu.claveTraduccion) }}</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'

// services
import { ColorimetryService } from '@shared/services/colorimetry.service'
import { BigDesktopService } from '@shared/services/util/bigDesktop.service'
import { DictonaryService } from '@shared/services/dictonary.service';

// Interfaces
import { IParamsColorimetry } from '@shared/interface/colorimetry.interface'

import { ITranslation } from '@shared/interface/translation.interface';
import { environment } from '@enviroment';

@Component({
  selector: 'app-colorimetry',
  templateUrl: './colorimetry.component.html',
  styleUrls: ['./colorimetry.component.scss']
})

export class ColorimetryComponent implements OnInit {
  public list:number[]
  public listItems: string[]

  public allParams: IParamsColorimetry
  public itemsToUpload: boolean = false
  public translation: ITranslation;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public service: ColorimetryService,
    public bigDesktopService: BigDesktopService,
    public dictionaryService: DictonaryService
  ) {
    this.list = [1,2,3,4,5]
    this.listItems = ['Eye', 'Skin', 'Hair']
    this.translation = environment.language;
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.service.category = params.get('category')
      this.service.gender = params.get('gender')
      this.service.subCategory = parseInt(params.get('subCategory'))
      this.itemsToUpload = false

      if(this.service.allData) this.service.filterAll(true)
    })
    this.translation.colorimetryTitle
    this.service.getColorimetries()
    this.bigDesktopService.isBigDesktop()
  }

  public getClasification(id: number) {
    this.router.navigate(
      ['/admin/colorimetria/'],
      { queryParams: {
        gender: this.service.gender,
        category: this.service.category,
        subCategory: id
      }}
    )
  }

  selectedCategory(subCategoryId: number){
    if(this.service.subCategory) {
      if(this.service.subCategory === subCategoryId)  return 'card-selected'
      else return ''
    }
    else {
      if(this.service.subCategorySection.subcategorias[0].id === subCategoryId) {
        return 'card-selected'
      }
      else return ''
    }
  }

  genderIcon(gender: string): string {
    switch (gender) {
      case 'Masculino': return 'fas fa-male'
      case 'Femenino': return 'fas fa-female'
      default: return 'fas fa-street-view'
    }
  }

  thereAreItemsToUpload() {
    this.itemsToUpload = this.service.thereAreItemsToUpload()
  }

  getFilesOfSubCategory(files: any, id: number) {
    if(files && id){
      this.service.getFilesToUploadSubCategory(files, id)
      this.thereAreItemsToUpload()
    }
  }

  getFilesOfClasification(
    files: any,
    clasificationId: number,
    subcategoryId: number,
    categoryId: number
    ){
    if(files && clasificationId && subcategoryId && categoryId ) {
      this.service.getFilesToUploadClasification(files, clasificationId, subcategoryId, categoryId)
      this.thereAreItemsToUpload()
    }
  }

  update = () => this.service.updateSubCategoryAndClasification()
}

<app-layout>
  <div class="container-fluid">
    <div class="text-center">
      <h2>
        {{  dictionaryService.getTranslation(translation.brandLevelTitle)  }}
      </h2>
      <div class="row mt-1 justify-content-center">
        <div class="col-12">
          <ng-container *ngIf="service.levels; else items">
            <ng-container *ngFor="let category of service.levels">
              <a
                class="btn p-2 m-1 btn-secondary mx-2 d-block d-md-inline-block my-1 "
                [routerLink]="['/admin/nivelmarca/']"
                [queryParams]="{ category: category.tipo}"
                routerLinkActive="active"
              >
              {{  dictionaryService.getTranslation(category.claveTraduccion)  }}
              </a>
            </ng-container>
          </ng-container>
          <ng-template #items>
            <a
              *ngFor="let item of listItems"
              class="btn btn-secondary  mx-2 d-block d-md-inline-block my-1"
              [routerLink]="['/admin/nivelmarca/']"
              routerLinkActive="active"
            >
              {{item}}
            </a>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="row mt-2 justify-content-center">
      <div class="col-12 text-right mb-2 mt-2 mt-xl-0">
        <ng-container *ngIf="edit; else noedit">
          <button class="btn btn-danger" (click)="changeEdit(false)">
            <i class="fas fa-window-close mr-2 d-inline"></i>
            <strong>
              {{ dictionaryService.getTranslation(translation.textCancel) }}
            </strong>
          </button>
        </ng-container>
        <ng-template #noedit>
          <button class="btn button" (click)="changeEdit(true)">
            <i class="fas fa-edit mr-2 d-inline"></i>
            <strong>
              {{ dictionaryService.getTranslation(translation.textEdit) }}
            </strong>
          </button>
        </ng-template>
      </div>

      <ng-container *ngIf="service.category; else List">
        <div
          class="col-12 col-md-6 col-lg-4 text-center"
          [ngClass]="bigDesktopService.bigDesktop ? 'col-xl-2' : 'col-xl-3'"
          *ngFor="let level of service.category.levels"
        >
          <ng-container *ngIf="edit; else noEditSectiion">
            <div class="mb-2">
              <app-file-upload
                (dataFiles)="addLevelToUpdate($event, level.id)"
                [urlImage]="level.imagen"
              ></app-file-upload>
              <button class="btn btn-secondary btn-block btn-bottom-card mt-2">
                {{level.nivel}}
              </button>
            </div>
          </ng-container>
          <ng-template #noEditSectiion>
            <div class="card">
              <div class="card-img-container ">
                <img
                  src={{level.imagen?level.imagen:imgURL}}
                  alt={{level.nivel}}
                  class="image"
                >
              </div>
              <div class="card-body text-center">
                <button class="btn btn-secondary btn-block btn-bottom-card">
                  {{level.nivel}}
                </button>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>

    <!-- nullDataList -->
    <ng-template #List>
      <div class="row my-3 justify-content-center">
        <div
          class="col-12 col-md-6 col-lg-4 mt-2 mb-4"
          [ngClass]="bigDesktop ? 'col-xl-2' : 'col-xl-3'"
          *ngFor="let item of list"
        >
        </div>
      </div>
    </ng-template>

    <!-- Save button-->
    <ng-container *ngIf="service.listOfLevelsToUpload.length > 0">
      <button
        class="btn btn-secondary btn-block btn-mobile my-4 py-2 "
        (click)="update()"
      >
        <i class="fas fa-cloud-upload-alt mr-2"></i>
        <strong class=" d-inline">
          {{  dictionaryService.getTranslation(translation.textSave)  }}
        </strong>
      </button>
    </ng-container>
  </div>
</app-layout>

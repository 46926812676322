import { Component, OnInit } from '@angular/core';

import { environment } from '@enviroment';

// Services
import { UserService } from '@shared/services/user.service';
import { DictonaryService } from '@shared/services/dictonary.service';

// Interfaces
import { ITranslation } from '@shared/interface/translation.interface';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public translationKey: ITranslation;

  constructor(
    public userService: UserService,
    public dictonaryService: DictonaryService
  ) {
    this.translationKey = environment.language;
  }

  ngOnInit(): void {}
}

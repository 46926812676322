import { Component, Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  public order_id = "";
  public order = null;
  public products = null;
  public total = 0;

  public paying = false;
  public payment_id = "";

  constructor(
    public _http: HttpClient, private _router: Router, private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getOrderInfo();
  }

  getOrderInfo(){
    //this.order_id = localStorage.getItem("orderId");
    this.order_id = this.activatedRoute.snapshot.paramMap.get('id')
    if(this.order_id == null){
      this._router.navigate(['/admin']);
    }
    else {
      this.getOrder().subscribe(
        response => {
          this.order = response.orders;
          this.products= response.products;
          this.calculateTotal();
          console.log(response)
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  calculateTotal(){
    this.total = 0;
    for(var i = 0; i < this.products.length; i++){
      if(this.products[i].status_id !== 3){
        this.total += this.products[i].total;
      }
    }
  }

  getStoreLink(id){
    this.getProductStoreLink(id).subscribe(
      response => {
        window.open(response.products.url_tienda, '_blank');
      },
      error => {
        console.log(error);
      }
    );
  }

  updateProduct(garment, size, cart, status){
    this.setProductStatus(garment, size, cart, status).subscribe(
      response => {
        console.log(response);
        this.getOrderInfo();
        if(status == 3){
          //producto no disponible, descontamos de total
          this.calculateTotal();
        }
      },
      error => {
        alert(error);
      }
    );
  }

  setPayment(){
    let confirmation = confirm("Todas las prendas han sido verificadas y la cantidad a cobrar es correcta");
    if(confirmation){
      //window.location.assign('https://checkout.outfit365.app/?order='+this.order_id+'&token='+this.order.payment_a+'&total='+this.total);
      this.paying = true;
      window.open('https://shop.outfit365.app/stripe-checkout/?order='+this.order_id+'&token='+this.order.payment_a+'&total='+this.total, '_blank');
      console.log("cobramos "+this.total);
    }
  }

  savePayment(){
    if(this.payment_id.trim() !== ""){
      this.setPaymentStatus(this.order_id, this.payment_id).subscribe(
        response => {
          //this._router.navigate(['/admin']);
          this.getOrderInfo();
        },
        error => {
          alert(error);
        }
      );
    }
  }

  updateCart(cart, status){
    this.setCartStatus(cart, status).subscribe(
      response => {
        //this._router.navigate(['/admin']);
        this.getOrderInfo();
      },
      error => {
        alert(error);
      }
    );
  }

  confirmClient(){
    let confirmation = confirm("Esta acción enviará el correo de confirmación de compra final al cliente");
    if(confirmation){
      this.updateCart(this.order_id, 5);
    }
  }





  //



  getOrder(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let json = {option: 3, id: this.order_id};

    return this._http.post('https://shop.outfit365.app/api-venta/service/order', json, {headers: headers});
  }

  getProductStoreLink(id): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let json = {option: 4, garment_id: id};

    return this._http.post('https://shop.outfit365.app/api-venta/service/order', json, {headers: headers});
  }

  setProductStatus(garment, size, cart, status): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let json = {option: 5, garment_id: garment, cart_id: cart, size: size, status_id: status};

    return this._http.post('https://shop.outfit365.app/api-venta/service/order', json, {headers: headers});
  }

  setCartStatus(cart, status): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let json = {option: 6, cart_id: cart, status_id: status};

    return this._http.post('https://shop.outfit365.app/api-venta/service/order', json, {headers: headers});
  }

  setPaymentStatus(cart, payment): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let json = {option: 7, cart_id: cart, payment_id: payment};

    return this._http.post('https://shop.outfit365.app/api-venta/service/order', json, {headers: headers});
  }

  

}

import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { environment } from '@enviroment';

// Interfaces
import { IResponse } from '@shared/interface/response.interface';

// Services
import { AlertService } from '@shared/services/alert.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private readonly urlBase: string;

  constructor(private httpClient: HttpClient, private alertService: AlertService, private router: Router) {
    this.urlBase = environment.api.base;
  }

  private response<T>(request: Observable<any>): Observable<T> {

    return new Observable<T>(observer => {

      request.subscribe((res: IResponse<T>) => {
        observer.next(res.data);
      }, (error: HttpErrorResponse) => {
        let text: string = environment.errorMessages.internalServer;

        if (error.status === 401) {
            localStorage.clear();
            this.router.navigateByUrl('/auth').then()
        }

        if (error.status !== 500) {
          text = error.error.message;
        }

        this.alertService.error(text);
        observer.next(null);
      });
    });
  }

  public get<T>(url: string, options?: any): Observable<T> {
    return this.response<T>(this.httpClient.get<IResponse<T>>(`${this.urlBase}${url}`, options));
  }

  public delete<T>(url: string, options?: any): Observable<T> {
    return this.response<T>(this.httpClient.delete<IResponse<T>>(`${this.urlBase}${url}`, options));
  }

  public post<T>(url: string, data: any, options?: any): Observable<T> {
    return this.response<T>(this.httpClient.post<IResponse<T>>(`${this.urlBase}${url}`, data, options));
  }

  public put<T>(url: string, data: any, options?: any): Observable<T> {
    return this.response<T>(this.httpClient.post<IResponse<T>>(`${this.urlBase}${url}?_method=PUT`, data, options));
  }

  public tempPut<T>(url: string, data: any): Observable<T> {
    return this.response<T>(this.httpClient.put<IResponse<T>>(`${this.urlBase}${url}`, data));
  }
}

import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router) { }

  public isAuthenticated(): boolean {
    return localStorage.getItem('token') !== null;
  }

  public saveUser(data): void {
    localStorage.setItem('data', JSON.stringify(data));
  }

  public getToken(): any {
    return JSON.parse(localStorage.getItem('token'));
  }

  public saveToken(data: any): void {
    localStorage.setItem('token', JSON.stringify(data));
  }

  public removeStorage(): void {
    localStorage.clear();
  }
}

import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';

// Services
import { UserService } from '@shared/services/user.service';

// Interfaces
import { IMenu } from '@shared/interface/sidebar.interface';
import { IDictonary } from '@shared/interface/dictonary.interface';
import { DictonaryService } from '@shared/services/dictonary.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  constructor(
    private router: Router,
    public userService: UserService,
    public dictonaryService: DictonaryService,
  ) {}

  ngOnInit() {
    this.userService.config();
  }

  public open(id: string): void {
    const element: any = $(`${id}`);

    if (element.hasClass('open')) {
      $(`${id}`).removeClass('open');
    } else {
      $('.dropdown').removeClass('open');
      $(`${id}`).addClass('open');
    }
  }

  public isOpen(parentUrl: string): boolean {
    return this.router.url.indexOf(parentUrl) !== -1;
  }

  public isSubMenu(item: IMenu): boolean {
    return item.submenu && item.submenu.length > 0;
  }
}

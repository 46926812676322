<div class="container-fluid">
    <div class="row">
        <div class="container-picture">
            <img [src]="imgURL">
        </div>
    </div>

    <div class="row" *ngIf="showProgress">
        <div *ngFor="let item of files" style="width: 100%; margin-top: 3%;">
            <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" attr.aria-valuenow="{{item.progress}}"
                aria-valuemin="0" aria-valuemax="100" [ngStyle]="{width:item.progress+'%'}">
                {{item.progress}}%
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="margin-top: 2%;">
        <button class="btn btn-danger" type="button" (click)="onClick()" style="margin: 0 auto; width: 200px;">  
            <i class="fas fa-upload"></i>  
            {{  dictionaryService.getTranslation("text_upload_file")  }}
        </button>  
        <input type="file" #fileUpload id="fileUpload" name="fileUpload" accept="image/*" style="display:none;"  (change)="onChange(fileUpload.files[0])"/>
    </div>
</div>
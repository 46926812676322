<app-layout>
  <div class="card">
    <h5 class="card-header bg-dark text-white text-left">
      <!-- {{dictionaryService.getTranslation(translation.outfitRules)}} -->
      Notifications
    </h5>

    <div class="card-body">
      <form [formGroup]="formNotification">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label for="gender">Genero</label>
              <select
                id="gender"
                class="form-control"
                formControlName="gender"
                appInputError
                [field]="formNotification.get('gender')"
                #genderSelect
                (change)="fillCatalogType(genderSelect.value)"
              >
                <option value="">Seleccione una opción</option>
                <option
                  value="{{ element.id }}"
                  *ngFor="let element of garmentService.catalogs.generos"
                >
                  {{
                    dictionaryService.getTranslation(
                        element.claveTraduccion
                    )
                  }}
                </option>
              </select>
              <app-form-error
                [submitted]="submitted"
                [control]="formNotification.get('gender')"
              ></app-form-error>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label for="style">Estilo</label>
              <select
                id="style"
                class="form-control"
                formControlName="style"
                appInputError
                [field]="formNotification.get('style')"
              >
                <option value="">Seleccione una opción</option>
                <option
                  value="{{ element.id }}"
                  *ngFor="let element of catalogStyle"
                >
                  {{
                    dictionaryService.getTranslation(
                        element.claveTraduccion
                    )
                  }}
                </option>
              </select>
              <app-form-error
                [submitted]="submitted"
                [control]="formNotification.get('style')"
              ></app-form-error>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label for="colorimetry">Colormetría</label>
              <select
                id="colorimetry"
                class="form-control"
                formControlName="colorimetry"
                appInputError
                [field]="formNotification.get('colorimetry')"
              >
                <option value="">Seleccione una opción</option>
                <option
                  value="{{ element.id }}"
                  *ngFor="let element of catalogColorimetry"
                >
                  {{
                    dictionaryService.getTranslation(
                        element.claveTraduccion
                    )
                  }}
                </option>
              </select>
              <app-form-error
                [submitted]="submitted"
                [control]="formNotification.get('colorimetry')"
              ></app-form-error>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label for="body-shape">Tipo de cuerpo</label>
              <select
                id="body-shape"
                class="form-control"
                formControlName="bodyShape"
                appInputError
                [field]="formNotification.get('bodyShape')"
              >
                <option value="">Seleccione una opción</option>
                <option
                  value="{{ element.id }}"
                  *ngFor="let element of catalogBodyType"
                >
                  {{
                    dictionaryService.getTranslation(
                        element.claveTraduccion
                    )
                  }}
                </option>
              </select>
              <app-form-error
                [submitted]="submitted"
                [control]="formNotification.get('bodyShape')"
              ></app-form-error>
            </div>
          </div>

          <div class="col-6 col-md-4 my-3">
            <h6 class="text-left mx-1">Título</h6>
            <div class="form-group">
              <input
                id="title"
                type="text"
                class="form-control"
                formControlName="title"
                appInputError
                [field]="formNotification.get('title')"
              >
              <app-form-error
                [submitted]="submitted"
                [control]="formNotification.get('title')"
              ></app-form-error>
            </div>
          </div>
          <div class="col-6 col-md-4 my-3">
            <h6 class="text-left mx-1">Mensaje de notificación</h6>
            <div class="form-group">
              <div class="form-floating">
                <textarea
                  class="form-control"
                  id="floatingTextarea"
                  formControlName="body"
                  appInputError
                  [field]="formNotification.get('body')"
                ></textarea>
                <app-form-error
                  [submitted]="submitted"
                  [control]="formNotification.get('body')"
                ></app-form-error>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="d-flex flex-grow-1 justify-content-center mt-5">
              <button class="btn btn-dark btn-lg" (click)="sendNotification()">
                <i class="fa fa-bell"></i>
                Enviar notificaciones
              </button>
            </div>
          </div>
        </div>

      </form>
    </div>

  </div>
</app-layout>
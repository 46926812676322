import { IEstatus, IUser } from '@shared/interface/user.interface';
import { ILanguage } from '@shared/interface/language.interface';

export class User {
  id: number;
  type: string;
  rol: string;
  name: string;
  email: string;
  status: IEstatus;
  lastName: string;
  firstName: string;
  language: ILanguage;
  lastAdmissionDate?: Date;

  constructor(user?: IUser) {
    this.parseToClass(user);
  }

  private parseToClass(user?: IUser): void {
    if (user) {
      this.id = user.id;
      this.rol = user.rol;
      this.type = user.tipo;
      this.name = user.nombre;
      this.email = user.correo;
      this.status = user.estatus;
      this.language = user.idioma;
      this.firstName = user.primerApellido;
      this.lastName = user.segundoApellido;
      this.lastAdmissionDate = user.fechaUltimoIngreso;
    }
  }

  public getNameUser(): string {
    return this.name + ' ' + this.firstName + ' ' + this.lastName;
  }

  public parseToObject(): IUser {
    return {
      id: this.id,
      rol: this.rol,
      tipo: this.type,
      nombre: this.name,
      correo: this.email,
      estatus: this.status,
      idioma: this.language,
      primerApellido: this.firstName,
      segundoApellido: this.lastName,
      fechaUltimoIngreso: this.lastAdmissionDate
    };
  }

  public getUserStore(): void {
    this.parseToClass(JSON.parse(localStorage.getItem('user')));
  }

  public toString(): string {
    return (
      this.id +
      ' ' +
      this.rol +
      ' ' +
      this.type +
      ' ' +
      this.name +
      ' ' +
      this.email +
      ' ' +
      this.language +
        ' ' +
      this.status +
      ' ' +
      this.firstName +
      ' ' +
      this.lastName +
      ' ' +
      this.lastAdmissionDate
    );
  }
}

import { FormArray, FormControl, ValidatorFn } from '@angular/forms';

export function validatePassword() {
  return function (input: FormControl) {
    const regex = new RegExp(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      'g'
    );
    return regex.test(input.value) ? null : { 'error-password': true };
  };
}

export function validateMatchPassword(nameControl: string) {
  return function (input: FormControl) {
    if (!input.root.get(nameControl)) {
      return null;
    }

    const password = input.root.get(nameControl).value;
    return password === input.value ? null : { 'match-error-password': true };
  };
}

export function minSelected(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      .map(control => control.value)
      .reduce((prev, next) => (next ? prev + next : prev), 0);

    return totalSelected >= min ? null : { requiredSelected: true };
  };

  return validator;
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { environment } from '@enviroment';

// Services
import { UserService } from '@shared/services/user.service';

// Interfaces
import { IUserLogin } from '@shared/interface/user.interface';
import { AlertService } from '@shared/services/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public images: any;

  public formLogin: FormGroup;

  constructor(private userService: UserService, private formBuilder: FormBuilder, private alertService: AlertService) {
    this.images = environment.images;

    this.formLogin = this.formBuilder.group({
      correo: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  public login() {

    if (this.formLogin.invalid) {
      this.alertService.error('Favor de llenar los campos requeridos');
      return;
    }

    const user: IUserLogin = { ...this.formLogin.value };

    this.userService.login(user);
  }
}
